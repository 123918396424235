import React, { Component } from "react";
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnimatedChart } from "./AnimatedChart";
import { playGameSound } from "../../Utility/gameSounds";
import { get, backendBot } from "../../Utility/httpClient";

class CrashGameCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousMessage: 'yet to receive',
      betAmount: localStorage.getItem("local_storage_wager") || 100,
      autoPayoutMultiplier: localStorage.getItem("local_storage_multiplier") || 2,
      liveMultiplier: 'Waiting for next round to start...',
      liveMultiplierSwitch: false,
      bBettingPhase: false,
      bettingPhaseTime: -1,
      liveBettingTable: null,
      globalTimeNow: 0,
      chartData: { datasets: [] },
      chartOptions: {},
      chartSwitch: false,
      gamePhaseTimeElapsed: 0,
      gameId: -1,
      startTime: null,
      myAmount: {},
      crashed: -1,
      crashedTime: 0,
      crash_width: 0
    };

    this.crash_area = React.createRef();
    this.multiplierCount = React.createRef([]);
    this.timeCount_xaxis = React.createRef([]);
    this.realCounter_yaxis = React.createRef(5);
  }

  // Create chart options
  createChartOptions = (liveMultiplier, gamePhaseTimeElapsed) => {
    const gpt = (Date.now() - this.state.globalTimeNow) / 1000.0;
    const current = 1.0024 * Math.pow(1.0718, gpt);
    const options = {
      events: [],
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.1,
        },
      },
      scales: {
        yAxes: {
          type: 'linear',
          position: 'right',
          title: {
            display: false,
            text: 'value',
          },
          min: 1,
          max: liveMultiplier > 2 ? liveMultiplier : 2,
          ticks: {
            color: 'rgba(255, 255, 255,1)',
            maxTicksLimit: 5,
            callback: (value) => {
              if (value % 0.5 === 0) return parseFloat(value).toFixed(2);
            },
          },
          grid: {
            display: true,
            color: 'rgba(255, 255, 255,0.2)',
          },
        },
        xAxes: {
          type: 'linear',
          title: {
            display: false,
            text: 'value',
          },
          max: gamePhaseTimeElapsed > 2 ? gamePhaseTimeElapsed : 2,
          ticks: {
            color: 'rgba(255, 255, 255,1)',
            maxTicksLimit: 5,
            callback: (value) => {
              if (gamePhaseTimeElapsed < 10) {
                if (value % 1 === 0) return value;
              } else {
                if (value % 10 === 0) return value;
              }
            },
          },
          grid: {
            display: true,
            color: 'rgba(255, 255, 255,0.2)',
          },
        },
      },
      plugins: {
        legend: { display: false },
      },
      animation: {
        x: {
          type: 'number',
          easing: 'linear',
          duration: 0,
          from: 5,
          delay: 0,
        },
        y: {
          type: 'number',
          easing: 'linear',
          duration: 0,
          from: 5,
          delay: 0,
        },
      },
    };

    return options;
  };

  // Handle crash event and start multiplier count
  handleCrashEvent = (jmsevent) => {
    if (jmsevent.data === 'keep-alive' || jmsevent.data == this.state.previousMessage) return;
    //@ts-ignore
    this.state.previousMessage = jmsevent.data
    const args = jmsevent.data.split(' ');
    switch (args[0]) {
      case 'start_multiplier_count': {
        this.multiplierCount.current = [];
        this.timeCount_xaxis.current = [];
        this.setState({
          gameId: args[1],
          globalTimeNow: Date.now(),
          liveMultiplierSwitch: true,
          crashed: -1,
        });
        this.props.setParentGameId(args[1]);
        playGameSound('/_crash/launch.wav');
        break;
      }
      case 'stop_multiplier_count': {
        const participants = this.props.getParticipants();
        this.setState({
          liveMultiplier: Number(args[1]).toFixed(2),
          crashed: Number(args[1]).toFixed(2),
          crashedTime: Date.now(),
        });
        this.props.setParentGameId(-1);
        this.props.addRoundToHistory(Number(args[1]).toFixed(2));
        this.props.setParentCrashed(Number(args[1]).toFixed(2), participants);
        this.props.setParticipants([]);
        playGameSound('/_crash/explosion.mp3');
        break;
      }
      case 'wager_placed': {
        let params = args[1].split(':');
        console.log('placed listener')
        if (params[3] && Number(params[3]) > 0.0) {
          this.props.getParticipants().filter(p => p.name === params[0]).map(p => p.multiplier = params[3]);
        } else {
          const participants = [...this.props.getParticipants()];
          participants.push({
            name: params[0],
            currency: params[2],
            amount: params[1],
            time: Date.now(),
          });
          this.props.setParticipants(participants);
        }
        break;
      }
      default:
        break;
    }
  };

  // Update multiplier during game
  updateMultiplier() {
    this.gameCounter = setInterval(() => {
      const timeElapsed = (Date.now() - this.state.globalTimeNow) / 1000.0;
      const multip = (1.0024 * Math.pow(1.0718, timeElapsed)).toFixed(2);
      this.setState({
        gamePhaseTimeElapsed: timeElapsed,
        liveMultiplier: multip,
      });

      if (this.multiplierCount.current.length < 1) {
        this.multiplierCount.current = this.multiplierCount.current.concat([1]);
        this.timeCount_xaxis.current = this.timeCount_xaxis.current.concat([0]);
      }

      if (this.realCounter_yaxis.current % 5 === 0) {
        if (timeElapsed > 1.3 && this.multiplierCount.current.length === 2) {
          for (let i = 1.001; i < timeElapsed; i++) {
            this.multiplierCount.current = this.multiplierCount.current.concat([(1.0024 * Math.pow(1.0718, i)).toFixed(2)]);
            this.timeCount_xaxis.current = this.timeCount_xaxis.current.concat([i]);
          }
        }
        this.multiplierCount.current = this.multiplierCount.current.concat([(1.0024 * Math.pow(1.0718, timeElapsed)).toFixed(2)]);
        this.timeCount_xaxis.current = this.timeCount_xaxis.current.concat([timeElapsed]);
      }
      this.realCounter_yaxis.current += 1;

      this.setState({
            chartData: {
                labels: this.timeCount_xaxis.current,
                    datasets: [
                    {
                        data: this.multiplierCount.current,
                        backgroundColor: "rgba(75,192,192,0.2)",
                        borderColor: "rgb(255,0,0)",
                        color: "rgba(255, 255, 255,1)",
                        pointRadius: 0,
                        borderDash: [0, 0],
                        lineTension: 0,
                    }
                    ],
               },
           chartOptions: this.createChartOptions(this.state.liveMultiplier, this.state.gamePhaseTimeElapsed)
      })
    }, 1);
  }

  // Start betting phase timer
  startBettingPhaseTimer() {
    this.bettingInterval = setInterval(() => {
      const timeElapsed = (Date.now() - this.state.globalTimeNow) / 1000.0;
      const timeRemaining = (5 - timeElapsed).toFixed(2);
      this.setState({ bettingPhaseTime: timeRemaining });
      if (timeRemaining < 0) {
        this.setState({ bBettingPhase: false });
      }
    }, 10);
  }

  // Listen for crash events
  componentDidMount() {
    const client = new EventSource("https://api.dragonsden.gg/general/crash-feed");
    client.addEventListener("crash", this.handleCrashEvent);
    this.setState({ crash_width: this.crash_area.current.clientWidth, chartSwitch: true, startTime: Date.now() });

    window.onresize = () => {
      this.setState({ crash_width: this.crash_area.current.clientWidth });
    };

    // Fetch current crash state on mount
    get(backendBot, '/api/crash-state').then((r) => {
      const time = Number(r.state);
      if (time > -1) {
        const timeElapsed = (Date.now() - time) / 1000.0;
        const current = 1.0024 * Math.pow(1.0718, timeElapsed);
        this.multiplierCount.current = [0.07, 1.001];
        this.timeCount_xaxis.current = [0, 0.07];
        this.setState({
          gameId: r.gameId,
          globalTimeNow: time,
          liveMultiplier: current,
          liveMultiplierSwitch: true,
          crashed: -1,
          participants: r.currentBets,
        });
      }
    });
  }

  // Cleanup listeners and intervals on unmount
  componentWillUnmount() {
    window.onresize = null;
    clearInterval(this.bettingInterval);
  }

  // Update multiplier and betting phase
  componentDidUpdate(prevProps, prevState) {
    if (prevState.liveMultiplierSwitch !== this.state.liveMultiplierSwitch && this.state.liveMultiplierSwitch) {
      this.updateMultiplier();
    }
    if (prevState.bBettingPhase !== this.state.bBettingPhase && this.state.bBettingPhase) {
      this.startBettingPhaseTimer();
    }
  }

  render() {
    const { chartData, chartOptions, crashed, crashedTime, liveMultiplier, crash_width } = this.state;

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div>
                <ToastContainer />
            </div>

            <div className="grid-container-main" style={{ height: '100%', width: '100%' }}>

                {<div className="effects-box" style={{ height: '100%', width: '100%' }}>
                    <div className="basically-the-graph" ref={this.crash_area} style={{ height: '100%', minHeight: '400px', width: '100%', position: 'relative' }}>
                        {chartData ? (<AnimatedChart
                        chartData={chartData} chartOptions={chartOptions} bBettingPhase={crashed != -1 ? 1 : -1} multiplierValue={this.props.getParticipants()} liveMultiplier={liveMultiplier} />) : ('')}
                        <div className="crash_area" style={{ position: "absolute", zIndex: 12, top: '30%', left: crash_width / 3.5, }}>
                            {(() => {
                                if (crashed != -1) {
                                    if ((((crashedTime + 8000) - Date.now()) / 1000).toFixed(2) > 5) {
                                        return <div>
                                            <h1 className="CrashValue Error">{crashed}x</h1>
                                            <h2 className="Error round">Round Over</h2>
                                        </div>
                                    }
                                    return (<div>
                                        <h2 className="preparing">Preparing Round</h2>
                                        <span className="starting">Starting in {Number((((crashedTime + 8000) - Date.now()) / 1000).toFixed(2)) < 0 ? '0.00' : (((crashedTime + 8000) - Date.now()) / 1000).toFixed(2)}s</span>
                                    </div>)
                                }
                                if (this.state.myAmount[0]?.multiplier) {
                                    return (
                                        <>
                                            {liveMultiplier !== 'Waiting for next round to start...' ? <h1>{liveMultiplier}x</h1> : <h2>{liveMultiplier}</h2>}
                                            <p className="current">Current Payout</p>
                                        </>
                                    )
                                }
                                return (
                                    <>
                                        {liveMultiplier !== 'Waiting for next round to start...' ? <h1>{liveMultiplier}x</h1> : <h2>{liveMultiplier}</h2>}
                                        <p className="current">Current Payout</p>
                                    </>
                                )
                            })()}
                        </div>
                    </div>

                </div>}
            </div>
        </div >
    );
  }
}

export default CrashGameCanvas;
