import AccountLinkingGuide from "../Components/AccountLinkingGuide";
import React from "react";

export function noGameAccountView(
    openLinkGuide: any, setOpenLinkGuide: any
) {
    return (<div className="Current-Tier">
        <div className="Current-Tier-Info">
            <div className="Current-Tier-Image">
                <img src="https://dragonsden.gg/silver.png"/>
            </div>
            <div className="Current-Tier-Content">
                <div className="Current-Tier-Title">
                    <h4>No Accounts</h4>
                </div>
                <div className="Current-Tier-Description">
                    <p>
                        You <b>do not</b> have any OSRS accounts <b>linked</b> to your Discord account. To learn more
                        about linking accounts <b>click the Link button</b> below.
                    </p>
                </div>
            </div>
        </div>
        <div className="Current-Tier-Claim">
            <a>
                <button onClick={() => setOpenLinkGuide(true)}>Link</button>
            </a>
        </div>
        <br/><br/><br/><br/><br/>

        <AccountLinkingGuide
            open={openLinkGuide}
            onClose={() => setOpenLinkGuide(false)}
        />
    </div>)
}