import React, {Component, RefObject} from 'react';
import {SliderThumb} from "@mui/material";
import {RollSlider} from "./FairnessSlider";
import {ProvablyFairSet} from "../API/api";
import Tabs from "../Components/Tabs/Tabs";
import {axiosGet} from "../Utility/httpClient";

function RollSliderThumbComponent(props: any) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <img src="/dice.svg" width={50}/>
        </SliderThumb>
    );
}

type ProvablyFairProps = {
    verifying?: string
}

type ProvablyFairState = {
    id?: string
    verifying?: ProvablyFairSet
}

const getBetData = async (id: string): Promise<ProvablyFairSet> => axiosGet(`/auth/verify-bet?id=${id}`)

class ProvablyFair extends Component<ProvablyFairProps, ProvablyFairState> {

    private tabs: RefObject<HTMLInputElement> | undefined;
    private slider: RefObject<HTMLInputElement> | undefined;

    constructor(props: any) {
        super(props);
        this.state = {id: props.verifying}
        this.forceProofTab = this.forceProofTab.bind(this)
        this.getGameSnapshot =  this.getGameSnapshot.bind(this)
    }

    forceProofTab() {
        //@ts-ignore
        this.tabs.setTab(1)
    }

    getGameSnapshot(verifying?: ProvablyFairSet) {
        if (!verifying) {
            return <span className="Heading"
                         style={{color: 'white'}}>Please select a live bet from below to see proof.</span>
        }
        if (verifying.game == 'DICE') {
            return (<div className="Full RollSlider">
                <RollSlider
                    ref={this.slider}
                    slots={{thumb: RollSliderThumbComponent}}
                    getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                    defaultValue={verifying ? verifying.value : 50}
                    disabled={true}
                    key={verifying ? verifying.value : 50}
                    valueLabelDisplay="on"
                    onChange={(e) => {

                    }}
                    marks={[{value: 0, label: '0',}, {value: 100, label: '100'}]}
                />
            </div>)
        }
        if (verifying.game == 'CRASH') {
            return (<div className="Full CrashProofBackground">
                <span className="Heading">Crashed @ {verifying.value}x</span>
            </div>)
        }
        if (verifying.game == 'SLOTS') {
            const reel = verifying.meta.split(' ')
            return (<div className="Full SlotsProofBackground">
                <ul>
                    <li><img src={`/assets/games/online/slots/sprites/symbols/${reel[0]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[1]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[2]}.png`}/></li>
                    <li><img src={`/assets/games/online/slots/sprites/symbols/${reel[3]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[4]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[5]}.png`}/></li>
                    <li><img src={`/assets/games/online/slots/sprites/symbols/${reel[6]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[7]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[8]}.png`}/></li>
                    <li><img src={`/assets/games/online/slots/sprites/symbols/${reel[9]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[10]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[11]}.png`}/></li>
                    <li><img src={`/assets/games/online/slots/sprites/symbols/${reel[12]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[13]}.png`}/><img src={`/assets/games/online/slots/sprites/symbols/${reel[14]}.png`}/></li>
                </ul>
            </div>)
        }
        if (verifying.game == 'ROULETTE') {
            return (<div className="Full RouletteProofBackground">
                <span className="Heading">{verifying.value}</span>
            </div>)
        }
        return (<span className="Heading" style={{color: 'white'}}>Game '{verifying.game}' Not Found</span>)
    }


    componentDidMount() {
        if (!this.state.verifying && this.state.id) {
            getBetData(this.state.id).then(r => this.setState({verifying: r}))
        }
    }

    render() {
        if (!this.state.verifying) {
            return (
                    <div className="FairnessModalTabs">
                                <div className="FairnessCanvas">
                                </div>
                                <div className="SeedValidation">
                                    <div className="FairnessCanvas">
                                        <br/>
                                    </div>
                                </div>
                            </div>
            );
        }
        return (
                <div className="FairnessModalTabs">

                            <div className="FairnessCanvas">
                                {
                                    this.getGameSnapshot(this.state.verifying)
                                }
                            </div>
                            <div className="SeedValidation">
                                <div className="FairnessCanvas">
                                    <br/>
                                    <div className="CanvasRow">
                                        <div className="RowHeader">
                                            <div className="Left">Client Seed</div>
                                        </div>
                                        <div className="RowHeader">
                                            <div className="CanvasField">
                                                <div className="AmountField">
                                                    <div className="IconValue">
                                                        <div className="ProfitValue" style={{textTransform: 'none'}}>{this.props.verifying ? this.state.verifying.clientSeed : '-'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="CanvasRow">
                                        <div className="RowHeader">
                                            <div className="Left">Server Seed</div>
                                        </div>
                                        <div className="RowHeader">
                                            <div className="CanvasField">
                                                <div className="AmountField">
                                                    <div className="IconValue">
                                                        <div className="ProfitValue" style={{textTransform: 'none'}}    >{this.props.verifying ? this.state.verifying.serverSeed : '-' }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="CanvasRow">
                                        <div className="RowHeader">
                                            <div className="Left">Nonce</div>
                                        </div>
                                        <div className="RowHeader">
                                            <div className="CanvasField">
                                                <div className="AmountField">
                                                    <div className="IconValue">
                                                        <div className="ProfitValue">{this.props.verifying ? this.state.verifying.nonce : '-' }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
        );
    }
}

export default ProvablyFair;