import '../../Assets/CSS/Editor.scss'
import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import {Footer} from "../../Components/Footer";
import {getLoadingBar} from "../../Utility/loadingBar";
import {toRuneScapeFormatFromExact} from "../../Utility/utils";
import {currencies} from "../../Utility/currencies";
import Plinko from "../../Games/Plinko/Plinko";
import {noGameAccountView} from "../../Utility/gameAccountLinking";
import {faVolumeHigh, faVolumeMute} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PlayPlinko: React.FC<any> = ({
    accountDetails,
    setCurrentProfile,
    profileDetails,
    balanceOverview,
    currentBalance,
    warn
}) => {

    const params = useParams();
    const [openLinkGuide, setOpenLinkGuide] = useState(false);
    const [muted, setMuted] = useState(localStorage.getItem('mute-sounds') == "true");

    useEffect(() => {
        if (!muted) {
            localStorage.removeItem('mute-sounds');
        } else {
            localStorage.setItem('mute-sounds', "true");
        }
    }, [muted])

    const getCurrentView = () => {
        return (<div className="Current-Tier-Container">
            {getAccountsView()}
        </div>)
    }

    function getAccountsView() {
        if ((profileDetails.accounts?.length ?? 0) == 0) {
            return noGameAccountView(openLinkGuide, setOpenLinkGuide)
        }

        let parameters = [ params.pathParam1,  params.pathParam2,  params.pathParam3 ]

        return <div className="OSRSAccount OSRSAccountGames">
            { balanceOverview ? (<div className="OSRSAccountSection OSRSAccountSectionGames">
                    <div className="OSRSAccountSectionExpandedHeader">
                        <div className="Heading">
                            <img src="/_plinko/ball.svg"/>
                            <h3>Plinko</h3>
                            <a onClick={() => setMuted(!muted)}><FontAwesomeIcon icon={muted ? faVolumeMute : faVolumeHigh} /></a>
                            <a>|</a>
                            <img width={25} className="Currency" src={`/currencies/${currencies.get(accountDetails.currency)!!.img}`}/>
                            <span>{currencies.get(accountDetails.currency)!!.symbol}{currencies.get(accountDetails.currency)!!.format(currentBalance)}</span>
                        </div>
                        <div className="OSRSAccountsBar OSRSAccountsBarExpanded">
                            <select defaultValue={accountDetails?.currentProfile} onChange={e => {
                                setCurrentProfile(e.target.value)
                            }}>
                                { profileDetails.accounts.map((c: any) => (<option value={c}>{c.replaceAll('@telegram', '')}</option>)) }
                            </select>
                        </div>
                    </div>

                    <Plinko
                        balance={currentBalance}
                        setBalance={() => {}}
                        currency={accountDetails.currency}
                        parameters={parameters}
                        warn={warn}
                    />

                </div>) :
                (<div className="OSRSAccountSection">
                    <div className="Heading">
                        <img src="/_plinko/ball.svg"/>
                        <h3>Plinko</h3>
                        <span>Loading...</span>
                    </div>
                    <div className="Loader-bar-space">
                        {getLoadingBar(true, "Fetching Information...")}
                    </div>
                </div>)
            }

        </div>
    }

    if (!profileDetails) {
        return (<>
            <div className="ProfileView"></div>
        </>)
    }
    return (<>
        <div className="App-contents">
            <div className="Landing-conten ProfileView">
                <div className="CurrentView">
                    {getCurrentView()}
                </div>
                <br/><br/><br/><br/><br/><br/>
                <Footer/>
            </div>
        </div>

    </>)
}
