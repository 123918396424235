import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineUndo, AiOutlineClear } from 'react-icons/ai';
import {playGameSound} from "../../Utility/gameSounds";
import {EffectButton} from "./EffectButton";
import {ChipComponent} from "./ChipComponent";
import {ButtonProps, getChipValue, ChipPosition} from "./rouletteTypes";

const chip_list: Array<string> = [
    "10K",
    "100K",
    "1M",
    "10M",
    "100M",
    "100K",
    "1M",
    "10M",
    "100M",
    "1B",
];
let Interval: any;
let Animate_Interval: any;
let startAngle = 0;
let Animate_setTimeout: any;
var spinTimeout: any = null;

const RouletteGameCanvas: any = (rest: any) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const RoulettePanel = useRef<HTMLDivElement>(null);
    const ImgRef = useRef<HTMLImageElement>(null);
    let roulette: any = canvasRef.current;
    const [size, setSize] = useState<Array<number>>([400, 200]);
    const [betList, setBetList] = useState<Array<number>>([]);
    const [chipValue, setChipValue] = useState("0");
    const [ballScreen, setBallScreen] = useState<Array<ChipPosition>>([]);
    const [history, setHistory] = useState<Array<Array<ChipPosition>>>([]);
    const [chipIndex, setChipIndex] = useState<number>(0);
    const [BetDisplay, setBetDisplay] = useState(false);
    const [currentState, setCurrentState] = useState<Array<any>>([]);
    const img = new Image();
    img.src = '/_roulette/Wheel.svg';
    let chip_index: number = 0;

    useLayoutEffect(() => {
        function updateSize() {
            roulette = RoulettePanel.current;
            setSize([
                roulette!.clientWidth,
                roulette!.clientHeight,
            ]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    var options = [0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26];

    var color_list = [1, 3, 5, 7, 9, 12, 14, 16, 18, 21, 19, 23, 27, 25, 30, 32, 34, 36];

    var button_list = ['1 to 18', 'Even', '', ' ', 'Odd', '19 to 36'];

    var arc = Math.PI / (options.length / 2);
    var spinTime = 0;
    var spinAngleStart = 0;

    var ctx: any;

    window.onscroll = (e) => {
        e.preventDefault();
    }

    useMemo(() => {
        spin();
        setBallScreen(
            [...new Array(49)].map((ele, index) => { return {position: index, chips: []} })
        )

    }, []);

    useEffect(() => {
        setChipValue(rest.chip_value)
        if (rest.doubleChip) {
            appendHistory()
            var newScreen = ballScreen.slice()
            newScreen.forEach(pos => pos.chips.forEach(chip => {
                chip.quantity = chip.quantity * 2
            }))
            setBallScreen(newScreen)
        }
        if (rest.divide) {
            appendHistory()
            var newScreen = ballScreen.slice()
            newScreen.forEach(pos => pos.chips.forEach(chip => {
                chip.quantity = Number(Math.floor(chip.quantity / 2).toFixed(0))
                if (chip.quantity < 1) {
                    chip.quantity = 1
                }
            }))
            setBallScreen(newScreen)
        }
    }, [rest.chip_value, rest.doubleChip, rest.divide]);

    function appendHistory() {
        let flat: Array<any> = []

        ballScreen.map(b => {
            let newChips: Array<any> = []
            b.chips.map(chip => {
                newChips.push({id: chip.id, quantity: chip.quantity})
            })
            flat[b.position] = newChips
        })
        history.push(
            [...new Array(49)].map((ele, index) => { return {position: index, chips: flat[index] } })
        )
    }

    useEffect(() => {
        if (ballScreen.length) {
            let total = 0;
            ballScreen.map((ele, id) => {
               ele.chips.map(chip => {
                   total += getChipValue(chip.id) * chip.quantity
               })
            });
            rest.setState(total)
        }
        rest.setBorderData(ballScreen)
    }, [ballScreen]);

    useEffect(() => {

        if (rest.onAnimation && window.innerWidth > 300) {
            if (rest.bet_number) {
                clearInterval(Interval);

                const canvas = canvasRef.current;
                ctx = canvas!.getContext("2d");
                if (window.innerWidth > 300) {
                    ballAnimation(ctx, canvas);
                }
                setTimeout(() => {
                    setBetList([rest.bet_number, ...betList]);
                    setBetDisplay(true);

                    clearInterval(Animate_Interval);
                    Interval = setInterval(() => {
                        var regex = /[\d|,|.|E|\+]+/g;
                        var strings: any = ImgRef.current?.style.transform;
                        var matches = strings.match(regex);
                        ctx.clearRect(0, 0, 500, 500);
                        let index: number = 0;
                        options.map((ele, id) => ele === rest.bet_number ? index = id : "");
                        let degree = - (Number(matches[0]) % 360) + (360 / 37 * index);
                        ctx.clearRect(0, 0, canvas?.clientWidth, canvas?.clientHeight);
                        ctx.beginPath();
                        ctx.save();
                        if (window.innerWidth > 300) {
                            ctx.translate(180, 190);
                            ctx.rotate(degree * Math.PI / 180);
                            ctx.arc(8, -121, 9, 0, 2 * Math.PI); // resting
                            ctx.fillStyle = "#ffffff";
                            ctx.fill();
                        }
                        ctx.restore();

                    }, 5)
                }, 2700);
            }
        } else {
            if (rest.bet_number) {
                // playGameSound("/_roulette/spinning.6587b785.mp3")
                clearInterval(Interval);
                clearInterval(Animate_Interval);
                setBetList([rest.bet_number, ...betList]);
                ctx.clearRect(0, 0, 500, 500);

                const canvas = canvasRef.current;
                ctx = canvas!.getContext("2d");
                setBetDisplay(true);

                Interval = setInterval(() => {
                    var regex = /[\d|,|.|E|\+]+/g;
                    var strings: any = ImgRef.current?.style.transform;
                    var matches = strings.match(regex);
                    ctx.clearRect(0, 0, 500, 500);
                    let index: number = 0;
                    options.map((ele, id) => ele === rest.bet_number ? index = id : "");
                    let degree = - (Number(matches[0]) % 360) + (360 / 37 * index);
                    ctx.clearRect(0, 0, canvas?.clientWidth, canvas?.clientHeight);
                    ctx.beginPath();
                    ctx.save();
                    if (window.innerWidth > 300) {
                        ctx.translate(180, 190);
                        ctx.rotate(degree * Math.PI / 180);
                        ctx.arc(8, -121, 9, 0, 2 * Math.PI);
                        ctx.fillStyle = "#ffffff";
                        ctx.fill();
                    }
                    ctx.restore();
                }, 5)
            }
        }
        // setBetDisplay(false);

    }, [rest.bet_number]);

    const ballAnimation = (ctx: CanvasRenderingContext2D, canvas: any) => {
        let speed: number = 0;
        let degree = 0;

        Animate_Interval = setInterval(() => {

            ctx.clearRect(0, 0, 500, 500);
            degree = 500 / 120 * speed;
            ctx.beginPath();
            ctx.save();
            ctx.translate(180, 190);
            ctx.rotate(degree * Math.PI / 180);
            ctx.arc(8, -140, 9, 0, 2 * Math.PI);
            ctx.fillStyle = "#ffffff";
            ctx.fill();
            ctx.restore();
            speed += 1;

        }, 10);

        Animate_setTimeout = setTimeout(() => {
            let distance = 140 - 100;
            let id = 0;
            speed = 0;
            let between = 50;
            clearInterval(Animate_Interval);
            let between_degree: number = 0;
            let width = -140;
            Animate_Interval = setInterval(() => {

                var regex = /[\d|,|.|E|\+]+/g;
                var strings: any = ImgRef.current?.style.transform;
                var matches = strings.match(regex);
                ctx.clearRect(0, 0, 550, 550);
                let index: number = 0;
                options.map((ele, id) => ele === rest.bet_number ? index = id : "");
                if (between_degree === 0)
                    between_degree = (- (Number(matches[0]) % 360) + (360 / 37 * index) + 25 + between) > 0 ? - (Number(matches[0]) % 360) + (360 / 37 * index) - 25 + 220 + between : - (Number(matches[0]) % 360) + (360 / 37 * index) + 250 + 360 + between;
                    // if ((- (Number(matches[0]) % 360) + (360 / 37 * index) + 25 + between) > 0) between_degree = - (Number(matches[0]) % 360) + (360 / 37 * index) - 25 + 220 + between
                    // else {
                    //     clearInterval(Animate_Interval);
                    //     clearTimeout(Animate_setTimeout);
                    //     setTimeout(() =>
                    //         ballAnimation(ctx, canvas), 10)
                    // }
                ctx.beginPath();
                ctx.save();
                ctx.translate(180, 190);
                if (id <= 100) {
                    degree = -220 + (between_degree / 100) * id - (between_degree / 500) * id;
                    ctx.rotate(degree * Math.PI / 180);
                    if (Math.floor(id / 10) % 2 !== 0) {
                        width = width + (distance / -45);

                    } else {
                        width = width + (distance / 25);
                    }
                    ctx.arc(8, width, 9, 0, 2 * Math.PI);
                    ctx.fillStyle = "#ffffff";
                    ctx.fill();
                    ctx.restore();
                } else {
                    ctx.rotate(degree * Math.PI / 180);
                    if (Math.floor(id / 5) % 2 !== 0) {
                        width = - 100 - (distance / 540);

                    } else {
                        width = -103.5 + (distance / 540);
                    }
                    ctx.arc(8, width, 9, 0, 2 * Math.PI);
                    ctx.fillStyle = "#ffffff";
                    ctx.fill();
                    ctx.restore();
                }
                id++;
            }, 13);
        }, 1200);
    }

    const number_order = () => {

        let number: Array<number> = [];
        let number1: Array<number> = [];
        let number2: Array<number> = [];
        [...new Array(36)].map((ele, id) => {
            if ((id + 1) % 3 === 0) {
                number.push(id + 1);
            }
            else if ((id + 1) % 3 === 2) {
                number1.push(id + 1);
            }
            else if ((id + 1) % 3 === 1) {
                number2.push(id + 1);
            }
        }
        )
        return number.concat(number1).concat(number2);
    }

    function drawRouletteWheel() {

        const canvas = canvasRef.current;
        if (canvas?.getContext) {

            ctx = canvas.getContext("2d");
            ctx.strokeStyle = "black";
            ctx.lineWidth = 2;

            ctx.font = 'bold 12px Helvetica, Arial';
            ctx.beginPath();

            ImgRef.current!.style.transform = "rotate(-" + startAngle * 180 / Math.PI + "deg)";
        }
    }

    function spin() {
        spinAngleStart = 0.6;
        spinTime = 0;
        rotateWheel();
    }

    function rotateWheel() {
        clearTimeout(spinTimeout)
        var spinAngle = spinAngleStart
        startAngle += (spinAngle * Math.PI / 180);
        drawRouletteWheel();
        spinTimeout = setTimeout(() => rotateWheel(), 30);
    }

    drawRouletteWheel();

    function placeChip(event: any, position: number) {
        if (chipValue == "0") {
            return
        }
        appendHistory();
        playGameSound("/_roulette/kenoAutoPick.46756983.mp3")
        setCurrentState([...currentState, '']);
        event.target.style.filter = "brightness(1)";
        const existing = ballScreen[Number(position)].chips.find(chip => chip.id == chipValue)
        if (existing) {
            existing.quantity = existing.quantity + 1
        } else {
            ballScreen[Number(position)].chips.push({ id: chipValue, quantity: 1})
        }
        setBallScreen(ballScreen.slice());
    }

    return (
        <div className='game-roulette'>
            <div className='game_top'>
                <div className='main'>
                    <div className='display_bet'>
                        {
                            BetDisplay &&
                                rest.bet_number ? (
                                rest.bet_number == 0 ?
                                    <div className="green">{rest.bet_number}</div> :
                                    color_list.find(item => item === rest.bet_number) ?
                                        <div className="red">{betList[0]}</div> :
                                        <div className="grey">{betList[0]}</div>
                            ) :
                                <div className="grey"></div>
                        }
                    </div>
                    <div className='roulette-panel' ref={RoulettePanel}>
                        <canvas id="canvas" className="RouletteCanvas" width={window.innerWidth >= 420 ? "380" : "300"} height={window.innerWidth >= 420 ? "380" : "300"} ref={canvasRef}></canvas>
                        <img src='/_roulette/Wheel.svg' className='wheel' ref={ImgRef} />
                    </div>
                    <div className='betting_history'>
                        {
                            betList.length !== 0 && (
                                betList.map((ele, id) => ele == 0 ?
                                    <div className="green" key={id} style={{ opacity: 1 - id / 6 }}>{ele}</div> :
                                    color_list.find(item => item === ele) ?
                                        <div className="red" style={{ opacity: 1 - id / 6 }}>{ele}</div> :
                                        <div className="grey" style={{ opacity: 1 - id / 6 }}>{ele}</div>
                                )
                            )
                        }
                    </div>
                </div>
                <div className='number'>
                    <div className='number-area'>
                        <div className='zero green'
                            onMouseOver={(event: any) => {
                                event.target.style.filter = "brightness(1.3)";
                            }}
                            onMouseLeave={(event: any) => {
                                event.target.style.filter = "brightness(1)";
                            }}
                             onClick={(event: any) => placeChip(event, 48)}
                            >{'0'}
                            <ChipComponent ballScreen={ballScreen} history={history} chipIndex={chipIndex} index={48} />
                        </div>
                        <div className='tops'>
                            <div className='number_list'>
                                {
                                    number_order().map((ele, id) =>
                                        color_list.find(item => item === ele) ?
                                            <div
                                                key={id}
                                                className="red"
                                                id={id.toString()}
                                                onMouseOver={(event: any) => {
                                                    event.target.style.filter = "brightness(1.5)";
                                                }}
                                                onMouseLeave={(event: any) => {
                                                    event.target.style.filter = "brightness(1)";
                                                }}
                                                onClick={(event: any) => placeChip(event, id)}
                                            >{ele}
                                                <ChipComponent ballScreen={ballScreen} history={history} chipIndex={chipIndex} index={id} />
                                            </div> :
                                            <div
                                                key={id}
                                                className="grey"
                                                id={id.toString()}
                                                onMouseOver={(event: any) => {
                                                    event.target.style.filter = "brightness(1.5)";
                                                }}
                                                onMouseLeave={(event: any) => {
                                                    event.target.style.filter = "brightness(1)";
                                                }}
                                                onClick={(event: any) => placeChip(event, id)}
                                            >{ele}
                                                <ChipComponent ballScreen={ballScreen} history={history} chipIndex={chipIndex} index={id} />
                                            </div>
                                    )
                                }
                            </div>

                            <div className='number_bottom'>
                                <div className='main' style={{ height: window.innerWidth > 800 ? '100px' : '385px' }}>
                                    <div>
                                        <EffectButton
                                            options={options}
                                            ballScreen={ballScreen}
                                            setBallScreen={setBallScreen}
                                            chipValue={chipValue}
                                            IndexNumber={36}
                                            label={'1 to 12'}
                                            minValue={0}
                                            maxValue={4}
                                            color_list={color_list}
                                            history={history}
                                            setHistory={setHistory}
                                            rest={chipIndex}
                                        />
                                        <EffectButton
                                            options={options}
                                            ballScreen={ballScreen}
                                            setBallScreen={setBallScreen}
                                            chipValue={chipValue}
                                            IndexNumber={37}
                                            label={'13 to 24'}
                                            minValue={4}
                                            maxValue={8}
                                            color_list={color_list}
                                            history={history}
                                            setHistory={setHistory}
                                            rest={chipIndex}
                                        />
                                        <EffectButton
                                            options={options}
                                            ballScreen={ballScreen}
                                            setBallScreen={setBallScreen}
                                            chipValue={chipValue}
                                            IndexNumber={38}
                                            label={'25 to 36'}
                                            minValue={8}
                                            maxValue={12}
                                            color_list={color_list}
                                            history={history}
                                            setHistory={setHistory}
                                            rest={chipIndex}
                                        />
                                    </div>
                                    <div>
                                        {
                                            button_list.map((ele, id) =>
                                                <EffectButton
                                                    key={id}
                                                    options={number_order()}
                                                    ballScreen={ballScreen}
                                                    setBallScreen={setBallScreen}
                                                    chipValue={chipValue}
                                                    IndexNumber={id + 39}
                                                    label={ele}
                                                    minValue={8}
                                                    maxValue={12}
                                                    color_list={color_list}
                                                    history={history}
                                                    setHistory={setHistory}
                                                    rest={chip_index}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='space'>
                            <div
                                onMouseOver={(event: any) => {
                                    number_order().map((ele, id) => {
                                        if (id < 12) { } else {
                                            document.getElementById(id.toString())!.style.filter = "brightness(0.7)";
                                            event.target.style.filter = "brightness(1.3)";
                                        }
                                    })
                                }}
                                onMouseLeave={(event: any) => {
                                    number_order().map((ele, id) => {
                                        if (id < 12) { } else {
                                            document.getElementById(id.toString())!.style.filter = "brightness(1)";
                                            event.target.style.filter = "brightness(1)";
                                        }
                                    })
                                }}
                                onClick={(event: any) => placeChip(event, 45)}
                            >{'2:1'}
                                <ChipComponent ballScreen={ballScreen} history={history} chipIndex={chipIndex} index={45} />
                            </div>
                            <div
                                onMouseOver={(event: any) => {
                                    number_order().map((ele, id) => {
                                        if (id >= 12 && id < 24) { } else {
                                            document.getElementById(id.toString())!.style.filter = "brightness(0.7)";
                                            event.target.style.filter = "brightness(1.3)";

                                        }
                                    })
                                }}
                                onMouseLeave={(event: any) => {
                                    number_order().map((ele, id) => {
                                        if (id >= 12 && id < 24) { } else {
                                            document.getElementById(id.toString())!.style.filter = "brightness(1)";
                                            event.target.style.filter = "brightness(1)";
                                        }
                                    })
                                }}

                                onClick={(event: any) => placeChip(event, 46)}>{'2:1'}
                                <ChipComponent ballScreen={ballScreen} history={history} chipIndex={chipIndex} index={46} />
                            </div>
                            <div
                                onMouseOver={(event: any) => {
                                    number_order().map((ele, id) => {
                                        if (id >= 24 && id < 36) { } else {
                                            document.getElementById(id.toString())!.style.filter = "brightness(0.7)";
                                            event.target.style.filter = "brightness(1.3)";

                                        }
                                    })
                                }}
                                onMouseLeave={(event: any) => {
                                    number_order().map((ele, id) => {
                                        if (id >= 24 && id < 36) { } else {
                                            document.getElementById(id.toString())!.style.filter = "brightness(1)";
                                            event.target.style.filter = "brightness(1)";
                                        }
                                    })
                                }}
                                onClick={(event: any) => placeChip(event, 47)}>{'2:1'}
                                <ChipComponent ballScreen={ballScreen} history={history} chipIndex={chipIndex} index={47} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='controls'>
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        if (history[history.length - 1]) {
                            setBallScreen(
                                [...new Array(49)].map((ele, index) => { return {position: index, chips: history[history.length - 1].find(c => c.position == index)?.chips ?? []} })
                            )
                            setHistory(history.slice(0, history.length - 1));
                        }
                    }}>
                    <i><AiOutlineUndo /></i>
                    <p>Undo</p>
                </div>
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        setBallScreen(
                            [...new Array(49)].map((ele, index) => { return {position: index, chips: []} })
                        )
                        setHistory(
                            []
                        )

                    }}>
                    <p>Clear</p>
                    <i><AiOutlineClear /></i>
                </div>
            </div>
        </div>
    )

}

export default RouletteGameCanvas;
