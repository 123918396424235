import '../Assets/CSS/Editor.scss'
import React, {useState} from "react";
import {Footer} from "../Components/Footer";
import {getLoadingBar} from "../Utility/loadingBar";
import {toRuneScapeFormatFromExact} from "../Utility/utils";
import {noGameAccountView} from "../Utility/gameAccountLinking";

export const Games: React.FC<any> = ({
    accountDetails,
    setCurrentProfile,
    profileDetails,
    balanceOverview,
    currentBalance
}) => {

    const [openLinkGuide, setOpenLinkGuide] = useState(false);

    const getCurrentView = () => {
        return (<div className="Current-Tier-Container">
            {getAccountsView()}
        </div>)
    }

    function getAccountsView() {
        if (profileDetails!!.accounts.length == 0) {
            return noGameAccountView(openLinkGuide, setOpenLinkGuide)
        }
        return <div className="OSRSAccount OSRSAccountGames">
            { balanceOverview ? (<div className="OSRSAccountSection OSRSAccountSectionGames">
                    <div className="OSRSAccountSectionExpandedHeader">
                        <div className="Heading">
                            <img src="/rocket.svg" />
                            <h3>Games</h3>
                            <span>Balance: {toRuneScapeFormatFromExact(currentBalance)} GP</span>
                        </div>
                        <div className="OSRSAccountsBar OSRSAccountsBarExpanded">
                            <select defaultValue={accountDetails.currentProfile} onChange={e => {
                                setCurrentProfile(e.target.value)
                            }}>
                                {
                                    profileDetails!!.accounts.map((c: any) => (<option value={c}>{c.replaceAll('@telegram', '')}</option>))
                                }
                            </select>
                        </div>
                    </div>

                </div>) :
                (<div className="OSRSAccountSection">
                    <div className="Heading">
                        <img src="/rocket.svg" />
                        <h3>Games</h3>
                        <span>Loading...</span>
                    </div>
                    <div className="Loader-bar-space">
                        {getLoadingBar(true, "Fetching Information...")}
                    </div>
                </div>)
            }

        </div>
    }

    if (!profileDetails) {
        return (<>
            <div className="ProfileView"></div>
        </>)
    }
    return (<>
        <div className="App-contents">
            <div className="Landing-conten ProfileView">
                <div className="CurrentView">
                    {getCurrentView()}
                </div>
                <br/><br/><br/><br/><br/><br/>
                <Footer/>
            </div>
        </div>

    </>)
}