export function getChipValue(id: string) {
    switch (id) {
        case "10K": return 1
        case "100K": return 10
        case "1M": return 100
        case "10M": return 1000
        case "100M": return 10000
        case "1B": return 100000
    }
    return 0;
}

export type ButtonProps = {
    options: Array<number>;
    ballScreen: Array<ChipPosition>;
    setBallScreen: Function;
    chipValue: string;
    IndexNumber: any;
    label: string;
    minValue: number;
    maxValue: number;
    color_list: Array<number>;
    history: Array<Array<ChipPosition>>;
    setHistory: Function;
    rest: number
}

export type ChipComponentProps = {
    ballScreen: Array<ChipPosition>,
    history: Array<Array<ChipPosition>>,
    chipIndex: number,
    index: number
}

export type ChipPosition = {
    position: number
    chips: Array<Chip>
}

export type Chip = {
    id: string
    quantity: number
}

export type RouletteState = {
  spinning?: boolean;
  wagerId?: number;
  stopOnMultiplier?: number;
  bet_number: any;
  chip_value: string;
  current_chip: number;
  open: boolean;
  onHistory: boolean;
  balance: number,
  skipUpdate?: boolean,
  wagerAmount: number,
  cryptoWagerAmount?: number,
  onWin: number,
  onWinIncreaseBy: number,
  onLoss: number,
  onLossIncreaseBy: number,
  stopOnLoss: number,
  stopOnWin: number,
  sessionProfit: number,
  previousResult: number | null,
  earnings: number
  autoing: boolean
  wonPrevious: boolean
  wagered: number
  wager: number
  profit: number
  wins: number | undefined
  losses: number | undefined
  winningsData: Array<any>
  betRoll_list: Array<any>
  startingBalance: number
  onAnimation: boolean
  onModal: boolean
  selectChipIndex: number,
  doubleChip: boolean,
  divide: boolean,
  currentChip: number,
  gain: number,
  percent: number,
  order: string,
  prize: number
};

