import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {axiosGet} from "../Utility/httpClient";
import {PlayerDetails, ShuffleChallenge, ShuffleChallengers, ShuffleGiveaway} from "../API/api";
import {endDate, numberWithCommas, toRuneScapeFormatFromExact} from "../Utility/utils";
import {Grid} from "@material-ui/core";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";
import TermsOfService from "../Components/TermsOfService";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import FAQ from "../Components/FAQ";

export type LandingProps = {
    userDetails: PlayerDetails | undefined
};

export const LandingFiat: React.FC<LandingProps> = ({ userDetails }) => {

    if (!userDetails) {
        return (<>
            <div className="App-contents">

                <div className="Landing-content" style={{minWidth: '100%'}}>
                    <br /><br /><br /><br />
                    <div className="Landing-heading Heading-Landing">
                        <h3 className="Main-heading" style={{fontSize: '120px'}}>Private BET</h3>
                        <div className="Heading-divider-fiat"/>
                        {<p className="Main-subheading">Exclusive UK Casino. Invitation Only.</p>}
                        <br /><br />
                        <div className="Leaderboard-btn Small-Large-btn">
                            <a style={{width: '250px', marginTop: '50px'}} href="https://t.me/+5LZh-2VPALY2MjBk">
                                <button style={{borderRadius: '5px'}} className="TelegramBtn" onClick={() => {}}>
                                    <img width={35} src="/telegram.svg" />
                                    Join Telegram</button>
                            </a></div>
                    </div>
                    <br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/>

                    <Footer />

                </div>

            </div>
        </>)
    }
    const topGames = [
        {
            title: 'Crash',
            splash: '/_crash/logo.svg',
            url: 'https://dragonsden.gg/crash',
        },
        {
            title: 'Roulette',
            splash: '/_roulette/logo.svg',
            url: 'https://dragonsden.gg/roulette',
        },
        {
            title: 'Plinko',
            splash: '/_plinko/logo.svg',
            url: 'https://dragonsden.gg/plinko',
        }
    ]
    return (<>
        <div className="App-contents">

            <div className="Landing-content" style={{minWidth: '100%'}}>
                <ul className="FundingButton">
                    <li><button>Deposit</button></li>
                    <li><button>Withdraw</button></li>
                </ul>
                <div className="GamesList">
                    <div className="AltContainer">
                        <h2>Top Games</h2>
                        <div className="GamesDivider"/>
                        <div className="GamesListSelection">
                            {
                                topGames.map(game => {
                                        return (<a href={game.url} ><div className="Game" style={{background: `url('${game.splash}')`}}>
                                            <div className="GameTitle">{game.title}</div>
                                        </div></a>)
                                    })
                            }
                        </div>
                    </div>
                </div>
                <br/>
                <div className="Leaderboard-btn Small-Large-btn">
                    <a style={{width: '250px', marginTop: '50px'}} href="https://t.me/+5LZh-2VPALY2MjBk">
                        <button style={{borderRadius: '5px'}} className="TelegramBtn" onClick={() => {}}>
                            <img width={35} src="/telegram.svg" />
                            Join Telegram
                        </button>
                    </a>
                </div>
                <br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/>

                <Footer />
            </div>

        </div>
    </>)
}