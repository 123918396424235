import React, {Component} from 'react';

import './Plinko.scss'
import Tabs from "../../Components/Tabs/Tabs";
import {currencies} from "../../Utility/currencies";
import {backendBot, get} from "../../Utility/httpClient";
import {Bodies, Body, Composite, Engine, Events, IEventCollision, Render, Runner, World} from "matter-js";
import {
    PlinkoMarking,
    config,
    getMultiplier,
    getMultiplierByLinesQnt,
    getBallSpin,
    PlinkoCanvasState
} from "./plinkoConfiguration";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import {gameModal} from "../gameModal";
import {formatBetAmount, formatBetAmountHeader, getAmountInput} from "../wagerInput";
import {generateRandom} from "../../Utility/utils";

class PlinkoCanvas extends Component<any, PlinkoCanvasState> {

    private plinkoRef?: React.RefObject<HTMLCanvasElement>;

    constructor(props: any) {
        super(props);
        this.state = {
            lastMultipliers: [ ],
            engine: Engine.create(),
            markings: new Map()
        }
        this.plinkoRef = React.createRef();
        this.addBall = this.addBall.bind(this)
        this.renderCanvas = this.renderCanvas.bind(this)
        this.onBodyCollision = this.onBodyCollision.bind(this)
        this.updateState = this.updateState.bind(this)
    }

    updateState(data: any) {
        this.setState(data)
    }

    componentDidMount() {
        this.renderCanvas()
        this.props.setDropBall(this.addBall)
    }

    createImage(text: string, colour: string) {
        let drawing = document.createElement("canvas");
        drawing.width = 100
        drawing.height = 100
        let ctx = drawing.getContext("2d");
        if (ctx) {
            ctx.fillStyle = colour;
            ctx.fillRect(0, 0, 100, 100);
            ctx.fill();
            ctx.fillStyle = "#000";
            ctx.font = "600 38px onyxfont";
            ctx.textAlign = "center";
            ctx.fillText(text, 50, 70);
        }

        return drawing.toDataURL('image/src');
    }

    renderCanvas() {
        const {
            pins: pinsConfig,
            colors,
            ball: ballConfig,
            engine: engineConfig,
            world: worldConfig
        } = config
        const worldWidth: number = worldConfig.width
        const worldHeight: number = worldConfig.height

        const pins: Body[] = []
        for (let l = 0; l < this.props.lines; l++) {
            const linePins = pinsConfig.startPins + l
            const lineWidth = linePins * pinsConfig.pinGap
            for (let i = 0; i < linePins; i++) {
                const pinX =
                    worldWidth / 2 -
                    lineWidth / 2 +
                    i * pinsConfig.pinGap +
                    pinsConfig.pinGap / 2

                const pinY =
                    worldWidth / this.props.lines + l * pinsConfig.pinGap + pinsConfig.pinGap

                const pin = Bodies.circle(pinX, pinY, pinsConfig.pinSize, {
                    label: `pin-${i}`,
                    render: {
                        fillStyle: '#F5DCFF'
                    },
                    isStatic: true
                })
                pins.push(pin)
            }
        }

        const floor = Bodies.rectangle(0, worldWidth + 30, worldWidth * 10, 40, {
            label: 'block-1',
            render: {
                visible: false
            },
            isStatic: true
        })

        const multipliers = getMultiplierByLinesQnt(this.props.lines, this.props.risk)

        const multipliersBodies: Body[] = []

        let lastMultiplierX: number =
            worldWidth / 2 - (pinsConfig.pinGap / 2) * this.props.lines - pinsConfig.pinGap
        lastMultiplierX -= 7;

        multipliers.forEach((multiplier, index) => {
            const blockSize = 30 // height and width
            const multiplierBody = Bodies.rectangle(
                lastMultiplierX + 31,
                worldWidth / this.props.lines + this.props.lines * pinsConfig.pinGap + pinsConfig.pinGap,
                blockSize,
                blockSize,
                {
                    label: multiplier.label + ':' + multiplier.sound + ':' + multiplier.value + ':' + index,
                    isStatic: true,
                    render: {
                        sprite: {
                            xScale: .3,
                            yScale: .3,
                            texture: this.createImage(multiplier.value + 'x', this.getColour(multiplier.historySelector))
                        }
                    }
                }
            )
            lastMultiplierX = multiplierBody.position.x
            multipliersBodies.push(multiplierBody)
        })
        Composite.add(this.state.engine.world, [
            ...pins,
            ...multipliersBodies,
            floor
        ])
        Events.on(this.state.engine, 'collisionActive', this.onBodyCollision)
        this.state.engine.gravity.y = engineConfig.engineGravity
        const element = document.getElementById('plinko-canvas')
        const engine = this.state.engine
        const render = Render.create({
            element: element!,
            canvas: this.plinkoRef?.current!!,
            bounds: {
                max: {
                    y: worldHeight,
                    x: worldWidth
                },
                min: {
                    y: 0,
                    x: 0
                }
            },
            options: {
                background: colors.background,
                hasBounds: true,
                width: worldWidth,
                height: worldHeight,
                wireframes: false,
            },
            engine
        })
        const runner = Runner.create()
        Runner.run(runner, this.state.engine)
        Render.run(render)
        this.setState({ clearCanvas: () => {
                World.clear(this.state.engine.world, true)
                Engine.clear(this.state.engine)
                render.canvas.remove()
                render.textures = {}
            }
        })
    }

    getColour(selector: string) {
        switch (selector) {
            case "Eight": {
                return '#e73350';
            }
            case "Seven": {
                return '#eb3b48';
            }
            case "Six": {
                return '#ec4940';
            }
            case "Five": {
                return '#ed5c3d';
            }
            case "Four": {
                return '#eb733a';
            }
            case "Three": {
                return '#f0873a';
            }
            case "Two": {
                return '#f29c3d';
            }
            case "One": {
                return '#f5b340';
            }
            default:
            case "Zero": {
                return '#f7c644';
            }

        }
    }

    addBall(boxValue: number) {
        const {
            pins: pinsConfig,
            colors,
            ball: ballConfig,
            engine: engineConfig,
            world: worldConfig
        } = config
        const worldWidth: number = worldConfig.width
        const ballSound = new Audio(`/_plinko/sounds/ball.wav`)
        ballSound.volume = 0.2
        ballSound.currentTime = 0
        ballSound.play()

        const minBallX =
            worldWidth / 2 - pinsConfig.pinSize * 3 + pinsConfig.pinGap
        const maxBallX =
            worldWidth / 2 -
            pinsConfig.pinSize * 3 -
            pinsConfig.pinGap +
            pinsConfig.pinGap / 2

        const multipliers = getMultiplierByLinesQnt(this.props.lines, this.props.risk)
//         let offset = generateRandom(0, 200) - 100
//         let friction = generateRandom(0, 100) / 100
//         const offsetFriction = [ offset, friction ]
        const offsetFriction = getBallSpin(this.props.lines, boxValue, multipliers);
        const ballX = maxBallX + offsetFriction[0];
        const ballColor = colors.text
        const ball = Bodies.circle(ballX, 20, ballConfig.ballSize, {
            restitution: 1,
            friction: offsetFriction[1],
            label: `ball:${offsetFriction[0]}:${offsetFriction[1]}`,
            id: new Date().getTime(),
            frictionAir: 0.05,
            collisionFilter: {
                group: -1
            },
            render: {
                fillStyle: ballColor
            },
            isStatic: false
        })
        Composite.add(this.state.engine.world, ball)
    }

    onBodyCollision(event: IEventCollision<Engine>) {
        const pairs = event.pairs
        for (const pair of pairs) {
            const { bodyA, bodyB } = pair

            if (bodyB.label.includes('ball') && bodyA.label.includes('block')) {
                bodyB.collisionFilter.group = 2
                World.remove(this.state.engine.world, bodyB)
                const split = bodyA.label.split(':');
                const multiplierSound = split[1]
                const multiplierSong = new Audio(`/_plinko/sounds/${multiplierSound}`)
                const lastMultipliers = [ Number(split[2]) ]
                this.state.lastMultipliers.map(r => {
                    if (lastMultipliers.length < 6) {
                        lastMultipliers.push(r)
                    }
                })
                this.setState({lastMultipliers: lastMultipliers})
                multiplierSong.currentTime = 0
                multiplierSong.volume = 0.2
                multiplierSong.play()
//                 const multiplierCollided = bodyA.label.split(':')[2]
//                 const indexCollided = bodyA.label.split(':')[3]
//                 const offsetUsed = bodyB.label.split(':')[1]
//                 const frictionUsed = bodyB.label.split(':')[2]
//
//                 if (indexCollided) {
//                     if (!this.state.markings.has(Number(indexCollided))) {
//                         this.state.markings.set(Number(indexCollided), [])
//                     }
//                     const marked: Array<PlinkoMarking> | undefined = this.state.markings.get(Number(indexCollided))
//                     if (marked) {
//                         const alreadyContains = marked.find(marking => marking.offset == Number(offsetUsed) && marking.friction == Number(frictionUsed))
//                         if (!alreadyContains) {
//                             marked.push({offset: Number(offsetUsed), friction: Number(frictionUsed)})
//                         }
//                     }
//                     const multipliers = getMultiplierByLinesQnt(this.props.lines, this.props.risk)
//                     if (this.state.markings.size == multipliers.length) {
//                         let hasLessThanRequired = false
//                         for (let [key, value] of this.state.markings) {
//                             if (value.length < 5) {
//                                 hasLessThanRequired = true
//                             }
//                         }
//                         if (!hasLessThanRequired) {
//                             window.alert('Marked all required positions!!')
//                             let output = `{ lines: ${this.props.lines}, multipliers: [`
//
//                             for (let [key, value] of this.state.markings) {
//                                 output += `{ index: ${key}, spins: [ `
//
//                                 value.forEach(v => {
//                                     output += `{ friction: ${v.friction}, offset: ${v.offset} },`
//                                 })
//
//                                 output += `] },`
//                             }
//
//                             output += '] },'
//                             window.alert('done!')
//                             console.log(output)
//                         }
//                     }
//                 }
            }
        }
    }

    getWinHistory() {
        return (
            <ul className="PlinkoHistory">
                {this.state.lastMultipliers.map((multiplier, index) => {
                    const className = getMultiplier(multiplier as any).historySelector
                    return <li className={className}>{multiplier}x</li>
                })}
            </ul>
        )
    }

    render() {
        return (
            <>
                <canvas className={""} key={this.props.lines} ref={this.plinkoRef} />
                { this.getWinHistory() }
            </>
            );
    }

}

export default PlinkoCanvas;
