import '../Assets/CSS/Editor.scss'
import React, {useEffect, useState} from "react";
import {axiosGet} from "../Utility/httpClient";
import {Footer} from "../Components/Footer";
import {ProvablyFairSet, Transaction, WagerDTO, WonChallenge, WonLeaderboardRanking} from "../API/api";
import {ChallengeWinsTable} from "../Components/ChallengeWinsTable";
import {LeaderboardHistoricalRankingsTable} from "../Components/LeaderboardHistoricalRankingsTable";
import {PlayerPublicWagersTable} from "../Components/PlayerPublicWagersTable";
import {getLoadingBar} from "../Utility/loadingBar";
import {toRuneScapeFormatFromExact} from "../Utility/utils";
import moment from "moment";
import {PlayerPublicTransactionTable} from "../Components/PlayerPublicTransactionTable";
import {noGameAccountView} from "../Utility/gameAccountLinking";
import {provablyFair} from "../Games/gameModal";

const getTransactions = async (username: string): Promise<Array<Transaction>> => axiosGet(`/auth/player-transactions?username=${username}`)
const getTopWins = async (username: string): Promise<Array<WagerDTO>> => axiosGet(`/profile/top-wins?username=${username}`);
const getChallengeWins = async (username: string): Promise<Array<WonChallenge>> => axiosGet(`/profile/challenges?username=${username}`);
const getLeaderboardPlacements = async (username: string): Promise<Array<WonLeaderboardRanking>> => axiosGet(`/profile/leaderboard-placements?username=${username}`);
const getWagers = async (username: string): Promise<Array<WagerDTO>> => axiosGet(`/profile/wagers?username=${username}`);

export const Profile: React.FC<any> = ({
    accountDetails,
    setCurrentProfile,
    profileDetails,
    balanceOverview
}) => {

    const [achievementsTab, setAchievementsTab] = useState(1);
    const [achievementsTabFilter, setAchievementsTabFilter] = useState(0);
    const [openLinkGuide, setOpenLinkGuide] = useState(false);
    const [provablyFairOpen, setProvablyFairOpen] = useState(false);
    const [verifying, setVerifying] = useState<string | undefined>(undefined);

    const [topWins, setTopWins] = useState<Array<WagerDTO> | undefined>(undefined);
    const [challengeWins, setChallengeWins] = useState<Array<WonChallenge> | undefined>(undefined);
    const [leaderboardPlacements, setLeaderboardPlacements] = useState<Array<WonLeaderboardRanking> | undefined>(undefined);
    const [wagers, setWagers] = useState<Array<WagerDTO> | undefined>(undefined);
    const [transactions, setTransactions] = useState<Array<Transaction> | undefined>(undefined);

    const getCurrentView = () => {
        return (<div className="Current-Tier-Container">
            {getAccountsView()}
        </div>)
    }

    useEffect(() => {
        if (accountDetails && accountDetails.currentProfile) {
            getTransactions(accountDetails.currentProfile.replaceAll(' ', '_')).then(r => setTransactions(r))
            getTopWins(accountDetails.currentProfile.replaceAll(' ', '_')).then(r => setTopWins(r))
            getChallengeWins(accountDetails.currentProfile.replaceAll(' ', '_')).then(r => setChallengeWins(r))
//             getLeaderboardPlacements(accountDetails.currentProfile.replaceAll(' ', '_')).then(r => setLeaderboardPlacements(r))
            getWagers(accountDetails.currentProfile.replaceAll(' ', '_')).then(r => setWagers(r))
        }
    }, []);

    function verifyBet(id: string) {
        setVerifying(id)
        setProvablyFairOpen(true)
    }

    function getTable() {
        if (achievementsTab == 2) {
            if (!leaderboardPlacements) {
                return (<div className="Loader-bar-space">
                    {getLoadingBar(true, "Fetching Information...")}
                </div>)
            }
            return (<LeaderboardHistoricalRankingsTable rows={leaderboardPlacements} key={"leaderboard-wins-" + leaderboardPlacements.length} />)
        }
        if (achievementsTab == 3) {
                return (<div className="Loader-bar-space">
                    {getLoadingBar(true, "Fetching Information...")}
                </div>)
        }
        if (!challengeWins) {
                return (<div className="Loader-bar-space">
                    {getLoadingBar(true, "Fetching Information...")}
                </div>)
        }
        return (<ChallengeWinsTable rows={challengeWins} key={"challenges-won-" + challengeWins.length} />)
    }

    function getAccountsView() {
        if (profileDetails!!.accounts.length == 0) {
            return noGameAccountView(openLinkGuide, setOpenLinkGuide)
        }
        return <div className="OSRSAccount">
            { balanceOverview ? (<div className="OSRSAccountSection">
                    <div className="OSRSAccountSectionExpandedHeader">
                        <div className="Heading">
                            <img src="/profiles.svg" />
                            <h3>Accounts</h3>
                            <span>Your In-Game Accounts.</span>
                        </div>
                        <div className="OSRSAccountsBar">
                            <select defaultValue={accountDetails.currentProfile} onChange={e => {
                                setTopWins(undefined)
                                setChallengeWins(undefined)
                                setLeaderboardPlacements(undefined)
                                setWagers(undefined)
                                setCurrentProfile(e.target.value)
                            }}>
                                {
                                    profileDetails!!.accounts.map((c: any) => (<option value={c}>{c.replaceAll('@telegram', '')}</option>))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="homeWidgets">
                        <div className="widgetLgNoBG">
                            <ul className="widgetSmList">
                                <ul>
                                    <li>Username</li><li>{balanceOverview.username.replaceAll('@telegram', '')}</li>
                                </ul>
                                <ul>
                                    <li>Member Since</li><li>{moment(balanceOverview.memberSince).fromNow()}</li>
                                </ul>
                                <ul>
                                    <li>Last Seen</li><li>{moment(balanceOverview.lastSeen).fromNow()}</li>
                                </ul>
                                <ul>
                                    <li>Bets Placed</li><li>{balanceOverview.totalBets}</li>
                                </ul>
                                <ul>
                                    <li>Total Wagered</li><li>{toRuneScapeFormatFromExact(balanceOverview.netWagered)}</li>
                                </ul>
                                <ul>
                                    <li>Win Streak</li><li>{balanceOverview.streak}</li>
                                </ul>
                                <ul>
                                    <li>Total Rakeback</li><li>{toRuneScapeFormatFromExact(balanceOverview.totalRakeback)}</li>
                                </ul>
                                <ul>
                                    <li>Net Winnings</li><li>{toRuneScapeFormatFromExact(balanceOverview.netWon)} GP</li>
                                </ul>
                                <ul>
                                    <li>Challenges</li><li>{toRuneScapeFormatFromExact(balanceOverview.challenges)}</li>
                                </ul>
                                <ul>
                                    <li>Balance</li>
                                    <li></li>
                                    <li>{toRuneScapeFormatFromExact(balanceOverview.totalBalance)} GP</li>
                                </ul>
                                <ul>
                                    <li></li>
                                    <li>Held</li>
                                    <li>{toRuneScapeFormatFromExact(balanceOverview.held)} GP</li>
                                </ul>
                                <ul>
                                    <li></li>
                                    <li>Fills</li>
                                    <li>{toRuneScapeFormatFromExact(balanceOverview.fills)} GP</li>
                                </ul>
                                <ul>
                                    <li></li>
                                    <li>Rakeback</li>
                                    <li>{toRuneScapeFormatFromExact(balanceOverview.rakeback)} GP</li>
                                </ul>
                                <ul>
                                    <li></li>
                                    <li>Owes</li>
                                    <li>{toRuneScapeFormatFromExact(balanceOverview.owes)} GP</li>
                                </ul>
                            </ul>
                        </div>
                    </div>

                </div>) :
                (<div className="OSRSAccountSection">
                    <div className="Heading">
                        <img src="/profiles.svg" />
                        <h3>Accounts</h3>
                        <span>Your In-Game Accounts.</span>
                    </div>
                    <div className="Loader-bar-space">
                        {getLoadingBar(true, "Fetching Information...")}
                    </div>
                </div>)
            }

            {/*{ topWins ? (<div className="OSRSAccountSection">*/}
            {/*    <div className="Heading">*/}
            {/*        <img src="/leaderboard-update-white.svg" />*/}
            {/*        <h3>Top Wins</h3>*/}
            {/*        <span>Your all-time biggest wins across all of our games.</span>*/}
            {/*     </div>*/}

            {/*     <div className="BiggestWins-List" key={"top-wins-" + topWins.length}>*/}
            {/*     { topWins.map(win => {*/}
            {/*            return (<div className="BiggestWin-Entry">*/}
            {/*                              <div className="EntryPic">*/}

            {/*                              </div>*/}
            {/*                              <div className="EntryValue">*/}
            {/*                                 <ul>*/}
            {/*                                     <li>10M GP</li>*/}
            {/*                                     <li>Win</li>*/}
            {/*                                 </ul>*/}
            {/*                                 <ul>*/}
            {/*                                     <li>1</li>*/}
            {/*                                 </ul>*/}
            {/*                                 <ul>*/}
            {/*                                     <li>8</li>*/}
            {/*                                     <li>Highest Streak</li>*/}
            {/*                                 </ul>*/}
            {/*                              </div>*/}
            {/*                          </div>*/}
            {/*                          )*/}
            {/*                 })*/}
            {/*             }*/}
            {/*     </div>*/}
            {/* </div>) :*/}
            {/* (<div className="OSRSAccountSection">*/}
            {/*    <div className="Heading">*/}
            {/*        <img src="/leaderboard-update-white.svg" />*/}
            {/*        <h3>Top Wins</h3>*/}
            {/*        <span>Your all-time biggest wins across all of our games.</span>*/}
            {/*     </div>*/}
            {/*    <div className="Loader-bar-space">*/}
            {/*    {getLoadingBar(true, "Fetching Information...")}*/}
            {/*    </div>*/}
            {/* </div>)*/}
            {/* }*/}
            {/*<div className="OSRSAccountSection">*/}
            {/*    <div className="Heading">*/}
            {/*        <img src="/achievements.svg" />*/}
            {/*        <h3>Achievements</h3>*/}
            {/*        <span>Leaderboards, Challenges, Giveaways and other Achievements.</span>*/}
            {/*     </div>*/}
            {/*     <div className="DDAchievements">*/}
            {/*         <div className="DDAchievements-Tabs">*/}
            {/*            <ul>*/}
            {/*                <li className={achievementsTab == 1 ? 'Active' : ''} onClick={() => setAchievementsTab(1)}>Challenges</li>*/}
            {/*                <li className={achievementsTab == 2 ? 'Active' : ''} onClick={() => setAchievementsTab(2)}>Leaderboards</li>*/}
            {/*                <li className={achievementsTab == 3 ? 'Active' : ''} onClick={() => setAchievementsTab(3)}>Giveaways</li>*/}
            {/*            </ul>*/}
            {/*         </div>*/}
            {/*         <div className="DDAchievements-TimeFilter">*/}
            {/*            <ul>*/}
            {/*                <li className={achievementsTabFilter == 0 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(0)}>All</li>*/}
            {/*                <li className={achievementsTabFilter == 1 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(1)}>YTD</li>*/}
            {/*                <li className={achievementsTabFilter == 2 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(2)}>6-Months</li>*/}
            {/*                <li className={achievementsTabFilter == 3 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(3)}>3-Months</li>*/}
            {/*                <li className={achievementsTabFilter == 4 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(4)}>1-Month</li>*/}
            {/*                <li className={achievementsTabFilter == 5 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(5)}>Week</li>*/}
            {/*                <li className={achievementsTabFilter == 6 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(6)}>Today</li>*/}
            {/*            </ul>*/}
            {/*         </div>*/}
            {/*         <div className="DDAchievements-Table">*/}
            {/*            {*/}
            {/*             getTable()*/}
            {/*             }*/}
            {/*         </div>*/}
            {/*     </div>*/}
            {/* </div>*/}

            {/*<div className="OSRSAccountSection">*/}
            {/*    <div className="Heading">*/}
            {/*        <img src="/refer.svg" />*/}
            {/*        <h3>Referrals</h3>*/}
            {/*        <span>Refer your friends to Dragon's Den and earn money as they place bets.</span>*/}
            {/*     </div>*/}
            {/*     <PlayerReferralsTable rows={[]} />*/}
            {/* </div>*/}

            <div className="OSRSAccountSection">

                    {provablyFairOpen && provablyFair(
                                            'OSRS',
                                            provablyFairOpen,
                                            () => setProvablyFairOpen(false),
                                            verifying
                                        )}

                <div className="Heading">
                    <img src="/tickets.svg" />
                    <h3>Wagers</h3>
                    <span>A history of all of the bets you've placed with Dragon's Den.</span>
                 </div>

                 { wagers ?
                     (<PlayerPublicWagersTable rows={wagers} key={"wagers-" + wagers.length} verifyBet={verifyBet}  />)
                     :
                     (<div className="Loader-bar-space">{ getLoadingBar(true, "Fetching Information...") }</div>)
                 }
             </div>

            <div className="OSRSAccountSection">
                <div className="Heading">
                    <img src="/tickets.svg" />
                    <h3>Transactions</h3>
                    <span>A history of all of your Transactions with Dragon's Den.</span>
                 </div>

                 { transactions ?
                     (<PlayerPublicTransactionTable rows={transactions} key={"wagers-" + transactions.length} />)
                     :
                     (<div className="Loader-bar-space">{ getLoadingBar(true, "Fetching Information...") }</div>)
                 }
             </div>

            <div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </div>

        </div>
    }

    if (!profileDetails) {
        return (<>
            <div className="ProfileView"></div>
        </>)
    }
    return (<>
        <div className="App-contents">
            <div className="Landing-conten ProfileView">
                <div className="CurrentView">
                    {getCurrentView()}
                </div>
                <br/><br/><br/><br/><br/><br/>
                <Footer/>
            </div>
        </div>

    </>)
}