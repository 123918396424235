import {ButtonProps} from "./rouletteTypes";
import {playGameSound} from "../../Utility/gameSounds";
import {ChipComponent} from "./ChipComponent";

export const EffectButton: React.FC<ButtonProps> = (props: ButtonProps) => {
       
const {
    options,
    ballScreen,
    setBallScreen,
    chipValue,
    IndexNumber,
    label,
    minValue,
    maxValue,
    color_list,
    history,
    rest
} = props
       
const brihtness_change = (event: any, ele: number, id: number, brightnessValue: number) => {
    switch (label) {
        case '1 to 12':
         if (id % 12 >= minValue && id % 12 < maxValue) { } else {
             document.getElementById(id.toString())!.style.filter = "brightness(" + brightnessValue + ")";
         }
         break;
                   case '13 to 24':
         if (id % 12 >= minValue && id % 12 < maxValue) { } else {
             var e = document.getElementById(id.toString());
             if (e) {
                 e.style.filter = "brightness(" + brightnessValue + ")";
             }
         }
         break;
                   case '25 to 36':
         if (id % 12 >= minValue && id % 12 < maxValue) { } else {
             var e = document.getElementById(id.toString());
             if (e) {
                 e.style.filter = "brightness(" + brightnessValue + ")";
             }
         }
         break;
                   case '1 to 18':
         if (ele < 19) { } else {
             var e = document.getElementById(id.toString());
             if (e) {
                 e.style.filter = "brightness(" + brightnessValue + ")";
             }
         }
         break;
                   case 'Even':
         if (ele % 2 === 0) { } else {
             var e = document.getElementById(id.toString());
             if (e) {
                 e.style.filter = "brightness(" + brightnessValue + ")";
             }
         }
         break;
                   case '':
         if (color_list.find((item: any) => item === ele)) { } else {
             var e = document.getElementById(id.toString());
             if (e) {
                 e.style.filter = "brightness(" + brightnessValue + ")";
             }
         }
         break;
                   case ' ':
         if (!color_list.find((item: any) => item === ele)) { } else {
             document.getElementById(id.toString())!.style.filter = "brightness(" + brightnessValue + ")";
         }
         break;
                   case 'Odd':
         if (ele % 2 === 1) { } else {
             var e = document.getElementById(id.toString());
             if (e) {
                 e.style.filter = "brightness(" + brightnessValue + ")";
             }
         }
         break;
                   case '19 to 36':
         if (ele >= 19) { } else {
             var e = document.getElementById(id.toString());
             if (e) {
                 e.style.filter = "brightness(" + brightnessValue + ")";
             }
         }
         break;
                   default:
         break;
               }
           }
       
           const large = label == '1 to 12' || label == '13 to 24' || label == '25 to 36'
       
           function placeChipEffect(event: any, position: number) {
               if (chipValue == "0") {
                   return
               }
               playGameSound("/_roulette/kenoAutoPick.46756983.mp3")
               history.push(ballScreen.slice())
               // setCurrentState([...currentState, '']);
               event.target.style.filter = "brightness(1)";
               const existing = ballScreen[Number(position)].chips.find(chip => chip.id == chipValue)
               if (existing) {
                   existing.quantity = existing.quantity + 1
               } else {
                   ballScreen[Number(position)].chips.push({ id: chipValue, quantity: 1})
               }
               setBallScreen(ballScreen.slice());
           }
       
           return (
               <div
                   className={`EffectButton ${large ? 'LargeEffectBtn' : ''} ${label === '' ? 'red' : ''}`}
                   onMouseOver={(event: any) => {
         options.map((ele, id) => {
             brihtness_change(event, ele, id, 0.7);
         })
                   }}
                   onMouseLeave={(event: any) => {
         options.map((ele, id) => {
             brihtness_change(event, ele, id, 1);
         })
                   }}
                   onClick={(event: any) => {
         if (chipValue == "0") {
             return
         }
         playGameSound("/_roulette/kenoAutoPick.46756983.mp3")
         placeChipEffect(event, IndexNumber)
                   }}>
                   <span>
         {(ballScreen[IndexNumber] && Number(ballScreen[IndexNumber]) !== 0) ? '' : label}
         {
             <ChipComponent ballScreen={ballScreen} history={history} chipIndex={rest} index={IndexNumber} />
         }</span>
               </div >
           )
}