import React, {useEffect, useState} from 'react';
import './Assets/CSS/App.scss';
import './Assets/CSS/Leaderboard.scss';
import './Assets/CSS/Challenges.scss';
import './Assets/CSS/Challenges.responsive.scss';
import './Assets/CSS/Giveaways.scss';
import './Assets/CSS/Giveaways.responsive.scss';
import './Assets/CSS/App.responsive.scss';
import './Assets/CSS/Profile.scss';
import './Assets/CSS/Games.scss';
import './Games/Game.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AdminHeader} from "./Components/AdminHeader";
import LoginModal from "./Components/LoginModal";
import {axiosGet} from "./Utility/httpClient";
import {AccountPlayerOverview, AccountProfile, PlayerDetails, TwitchAuthCodeGrantFlow} from "./API/api";
import {Bots} from "./Routes/Bots";
import {AdminDashboard} from "./Routes/AdminDashboard";
import {HorizontalHeader} from "./Components/HorizontalHeader";
import {HallInfo} from "./Components/HallInfo";
import {Landing} from "./Routes/Landing";
import {LandingFiat} from "./Routes/LandingFiat";
import {Stats} from "./Routes/Stats";
import {Players} from "./Routes/Players";
import {AdminChallenges} from "./Routes/AdminChallenges";
import {AdminLeaderboards} from "./Routes/AdminLeaderboards";
import {AdminChatBot} from "./Routes/AdminChatBot";
import {AdminGiveaways} from "./Routes/AdminGiveaways";
import {KotlinCodeEditor} from "./Routes/KotlinCodeEditor";
import {Profile} from "./Routes/Profile";
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import {Cake, EmojiEvents, People} from "@material-ui/icons";
import {MilitaryTech, PrecisionManufacturing} from "@mui/icons-material";
import {Accounts} from "./Routes/Accounts";
import {AdminAccountView} from "./Routes/AdminAccountView";
import {Games} from "./Routes/Games";
import {PlayCrash} from "./Routes/Play/PlayCrash";
import {PlayRoulette} from "./Routes/Play/PlayRoulette";
import {PlayPlinko} from "./Routes/Play/PlayPlinko";
import {accountEvents} from "./Utility/serverEventSource";
import {Snackbar} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const getDetails = async (): Promise<PlayerDetails> => axiosGet(`/auth/details`)
const getProfile = async (): Promise<AccountProfile> => axiosGet(`/auth/profile`)
const getProfileBalance = async (username: string): Promise<AccountPlayerOverview> => axiosGet(`/auth/player-overview?username=${username}`)
const getAccessToken = async (code: string): Promise<TwitchAuthCodeGrantFlow> => axiosGet(`/auth/discord?code=${code}`)
const setCurrentProfileServer = async (name: string): Promise<AccountProfile> => axiosGet(`/auth/current-profile?profile=${name}`)

export default function App() {
    const [open, setOpen] = React.useState<boolean>(true)
    const [details, setDetails] = React.useState<PlayerDetails | undefined>(undefined)
    const [profile, setProfile] = useState<AccountProfile | undefined>(undefined);
    const [currentBalance, setCurrentBalance] = useState<number>(0);
    const [balanceOverview, setBalanceOverview] = useState<AccountPlayerOverview | undefined>(undefined);

    const [panelVisible, setPanelVisible ] = React.useState<boolean>(true)
    const hasToken = localStorage.getItem("bearer")

    async function checkDiscordLogin() {
        const url = window.location.href;
        const codeMatches = url.match(/code=([^&]*)/);
        const errorMatches = url.match(/error=([^&]*)/);
        let code = undefined;
        let error = undefined;
        const hasToken = localStorage.getItem("bearer") != null;
        if (codeMatches) {
            code = codeMatches[0].substring(5);
        } else if (errorMatches) {
            error = errorMatches[0].substring(6);
        }
        if (code) {
            if (hasToken) {
                localStorage.removeItem("bearer");
                localStorage.removeItem("refresh");
                localStorage.removeItem("expiry");
            }
            localStorage.setItem("twitch", code);
            await fetchToken();
            //@ts-ignore
            window.location.href = process.env.REACT_APP_TWITCH_REDIRECT_URI;
        } else if (error) {
            if (hasToken) {
                localStorage.removeItem("bearer");
                localStorage.removeItem("refresh");
                localStorage.removeItem("expiry");
            }
        } else if (hasToken) {
            if (localStorage.getItem("bearer")!.toString().length > 0) {
                getDetails().then((response: PlayerDetails) => {
                    setUserDetails(response)
                }).catch(err => {
                    localStorage.removeItem("bearer");
                    localStorage.removeItem("refresh");
                    localStorage.removeItem("expiry");
                })
            }
        }
    }

    async function fetchToken() {
        while (localStorage.getItem("bearer") == null || localStorage.getItem("bearer")!.length == 0) {
            await getAccessToken(localStorage.getItem("twitch")!).then(response => {
                localStorage.setItem("bearer", response.access_token);
                localStorage.setItem("refresh", response.refresh_token);
                localStorage.setItem("expiry", `${response.expires_in}`);
            });
        }
    }

    const [userDetails, setUserDetails] = useState<PlayerDetails | undefined>(undefined);
    const [warning, setWarning] = React.useState<string | undefined>(undefined)

    const warn = (message: string) => {
        setWarning(message)
    }

    const closeSnackbar = () => {
        setWarning(undefined)
    }

    const snackbarAction = (
        <React.Fragment>
            <FontAwesomeIcon icon={faTimes} />
        </React.Fragment>
    );

    useEffect(() => {
        checkDiscordLogin()
    }, [])

    useEffect(() => {
        if (hasToken) {
            getDetails().then(r => loadCurrentProfile(r)).catch(e => {
//                 localStorage.removeItem("bearer")
//                 window.location.reload()
            })
        }
    }, [])

    const loadCurrentProfile = (r: PlayerDetails) => {
        setDetails(r)
        getProfile().then(r => {
            setProfile(r)
        }).catch(r => window.location.href='https://dragonsden.gg/')
        getProfileBalance(r.currentProfile.replaceAll(' ', '_')).then(r => {
            setBalanceOverview(r)
            setCurrentBalance(r.held)
        })
        accountEvents(r.currentProfile.replaceAll(' ', '_'), (username: string, balance: number) => {
            setCurrentBalance(balance)
            getDetails().then(r => setDetails(r)).catch(e => {})
        })
    }

    const setCurrentProfile = (profile: string) => {
        setCurrentProfileServer(profile.replaceAll(" ", "_")).then(pr => {
            getDetails().then(r => loadCurrentProfile(r)).catch(e => {
//                 localStorage.removeItem("bearer")
//                 window.location.reload()
            })
        })
    }

    const toggleSelector = panelVisible ? '' : 'Navigation-Items-Mobile';
    if (window.location.href.includes("/uk")) {
        localStorage.setItem("fiat-user", "true")
        localStorage.removeItem("bearer")
        window.location.href = 'https://dragonsden.gg/'
    }
    if (window.location.href.includes("/global")) {
        localStorage.removeItem("fiat-user")
        localStorage.removeItem("bearer")
        window.location.href = 'https://dragonsden.gg/'
    }
    if (window.location.href.includes("/admin")) {
        if (!details) {
            return (
                <div className="App-admin">
                    <AdminHeader userDetails={details} togglePanelVisible={() => {}}/>
                    <div className="App-contents-container"></div>
                    <LoginModal
                        open={open && !hasToken}
                        onClose={() => setOpen(false)}
                    />
                </div>
            );
        }
        if (details.authority == 'USER') {
            window.location.href = 'https://dragonsden.gg/'
        }
        return (
            <div className="App-admin">
                <AdminHeader userDetails={details} togglePanelVisible={() => {}}/>
                <div className="MobileNavBarHeader Navigation-Items-Mobile">
                <div className="App-logo" onClick={() => setPanelVisible(!panelVisible) }>
                            <img width={45} height={45} src="/gold.png"/>
                        </div>
                        </div>
                <div className="App-contents-container">
                    <BrowserRouter>
                        <Routes>
                            <Route element={<AdminDashboard userDetails={details}/>} path="/admin"/>
                            <Route element={<Stats userDetails={details}/>} path="/admin/stats"/>
                            <Route element={<Bots userDetails={details}/>} path="/admin/bots"/>
                            <Route element={<Players userDetails={details}/>} path="/admin/players"/>
                            <Route element={<AdminAccountView userDetails={details}/>} path="/admin/account/:id"/>
                            <Route element={<Accounts userDetails={details}/>} path="/admin/accounts"/>
                            <Route element={<AdminChallenges userDetails={details}/>} path="/admin/challenges"/>
                            <Route element={<AdminLeaderboards userDetails={details}/>} path="/admin/leaderboards"/>
                            <Route element={<AdminGiveaways userDetails={details}/>} path="/admin/giveaways"/>
                            <Route element={<AdminChatBot userDetails={details}/>} path="/admin/chat-bots"/>
                        </Routes>
                    </BrowserRouter>
            <div className={`Mobile-navigation ${toggleSelector}`} onClick={() => {
                setPanelVisible(!panelVisible)}
            }>
                    <div className="Mobile-Close">
                        <div className="Action">
                            <CloseIcon />
                        </div>
                        <div className="Text">
                            <p>Menu</p>
                        </div>
                    </div>
                        <br /><br /><br /><br /><br />
                    <div className="Mobile-Menu">
                        {
                            <ul>
                                <li>
                                    <a href="https://dragonsden.gg/admin">
                                        <div className="Menu-icon"><HomeIcon /></div>
                                        <div className="Menu-option"><span>Dashboard</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://dragonsden.gg/admin/stats">
                                        <div className="Menu-icon">
                                        <svg id="Layer_1" width={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                                                        <g id="Layer_7">
                                                            <g id="Group_19">
                                                                <rect id="Rectangle_107" className="cls-1" x="62.966" y="5.268" width="20.366"
                                                                      height="81.464" rx="4.073" ry="4.073"/>
                                                                <rect id="Rectangle_108" className="cls-1" x="34.453" y="35.817" width="20.366"
                                                                      height="50.915" rx="4.073" ry="4.073"/>
                                                                <rect id="Rectangle_109" className="cls-1" x="5.941" y="64.329" width="20.366"
                                                                      height="22.403" rx="4.073" ry="4.073"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                        </div>
                                        <div className="Menu-option"><span>Stats</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://dragonsden.gg/admin/bots">
                                        <div className="Menu-icon">
                                            <PrecisionManufacturing width={25} />
                                       </div>
                                        <div className="Menu-option"><span>Bots</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://dragonsden.gg/admin/players">
                                        <div className="Menu-icon">
                                            <People width={25} />
                                        </div>
                                        <div className="Menu-option"><span>Players</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://dragonsden.gg/admin/challenges">
                                        <div className="Menu-icon">
                                        <EmojiEvents width={25}/>
                                        </div>
                                        <div className="Menu-option"><span>Challenges</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://dragonsden.gg/admin/leaderboards">
                                        <div className="Menu-icon">
                                            <MilitaryTech width={25} />
                                        </div>
                                        <div className="Menu-option"><span>Leaderboards</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://dragonsden.gg/admin/giveaways">
                                        <div className="Menu-icon">
                                            <Cake width={25} />
                                        </div>
                                        <div className="Menu-option"><span>Giveaways</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://dragonsden.gg/admin/chat-bots">
                                        <div className="Menu-icon">

                                           <PrecisionManufacturing width={25} />
                                           </div>
                                        <div className="Menu-option"><span>Chat Bots</span></div>
                                    </a>
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="Mobile-Tos">
                        <div className="Tos">
                            <span>Privacy Policy</span>
                            <span>Terms of Service</span>
                        </div>
                    </div>
            </div>

                <Snackbar
                            open={warning !== undefined}
                            autoHideDuration={6000}
                            onClose={closeSnackbar}
                            message={warning}
                            className="Snackbar"
                            action={snackbarAction}
                        />

                </div>
            </div>
        );
    }

    let optimiseMobileView = false
    const telegramLogin = localStorage.getItem("fiat-user");
    if (telegramLogin || window.location.href.includes('/crash') || window.location.href.includes('/roulette') || window.location.href.includes('/plinko')) {
        optimiseMobileView = true
    }

    if (!details) {
        return (
            <div className="App">
                <HorizontalHeader userDetails={details} togglePanelVisible={() => {}}/>
                { optimiseMobileView ? <></> : <HallInfo/> }
                <div className="App-contents-container">
                    <BrowserRouter>
                        <Routes>
                            { telegramLogin ?
                                <Route element={<LandingFiat userDetails={details}/>} path="/"/>
                                :
                                <Route element={<Landing userDetails={details}/>} path="/"/>
                            }
                            <Route element={<KotlinCodeEditor/>} path="/kotlin-compiler/:id"/>
                        </Routes>
                    </BrowserRouter>

                    <Snackbar
                                open={warning !== undefined}
                                autoHideDuration={6000}
                                onClose={closeSnackbar}
                                message={warning}
                                className="Snackbar"
                                action={snackbarAction}
                            />
                </div>
            </div>)
    }
    return (
        <div className="App">
            <HorizontalHeader userDetails={details} togglePanelVisible={() => {}}/>
            { optimiseMobileView ? <></> : <HallInfo/> }
            <div className="App-contents-container"
                 key={details?.currentProfile ?? 'loggedout'}
            >
                <BrowserRouter>
                    <Routes>
                            { telegramLogin ?
                                <Route element={<LandingFiat userDetails={details}/>} path="/"/>
                                :
                                <Route element={<Landing userDetails={details}/>} path="/"/>
                            }
                        <Route element={<KotlinCodeEditor />} path="/kotlin-compiler/:id"/>
                        <Route element={
                            <Profile
                                accountDetails={details}
                                setCurrentProfile={setCurrentProfile}
                                profileDetails={profile}
                                balanceOverview={balanceOverview}
                                currentBalance={currentBalance}
                            />
                        } path="/profile"/>
                        <Route element={
                            <Games
                                accountDetails={details}
                                setCurrentProfile={setCurrentProfile}
                                profileDetails={profile}
                                balanceOverview={balanceOverview}
                                currentBalance={currentBalance}
                            />
                        } path="/games"/>
                        <Route element={
                            <PlayCrash
                                accountDetails={details}
                                setCurrentProfile={setCurrentProfile}
                                profileDetails={profile}
                                balanceOverview={balanceOverview}
                                currentBalance={currentBalance}
                                warn={warn}
                            />
                        } path="/crash"/>
                        <Route element={
                            <PlayRoulette
                                accountDetails={details}
                                setCurrentProfile={setCurrentProfile}
                                profileDetails={profile}
                                balanceOverview={balanceOverview}
                                currentBalance={currentBalance}
                                warn={warn}
                            />
                        } path="/roulette"/>
                        <Route element={
                            <PlayPlinko
                                accountDetails={details}
                                setCurrentProfile={setCurrentProfile}
                                profileDetails={profile}
                                balanceOverview={balanceOverview}
                                currentBalance={currentBalance}
                                warn={warn}
                            />
                        } path="/plinko/:pathParam1?/:pathParam2?/:pathParam3?"/>
                    </Routes>
                </BrowserRouter>
                <Snackbar
                            open={warning !== undefined}
                            autoHideDuration={6000}
                            onClose={closeSnackbar}
                            message={warning}
                            className="Snackbar"
                            action={snackbarAction}
                        />
            </div>
        </div>
    );


}

