import React, {Component} from 'react';

import './Plinko.scss'
import Tabs from "../../Components/Tabs/Tabs";
import {currencies} from "../../Utility/currencies";
import {backendBot, get} from "../../Utility/httpClient";
import {Bodies, Body, Composite, Engine, Events, IEventCollision, Render, Runner, World} from "matter-js";
import {
    config,
    getMultiplier,
    getMultiplierByLinesQnt,
    getBallSpin,
    PlinkoGameState
} from "./plinkoConfiguration";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import {gameModal} from "../gameModal";
import PlinkoCanvas from "./PlinkoCanvas";
import {formatBetAmount, formatBetAmountHeader, getAmountInput} from "../wagerInput";

class Plinko extends Component<any, PlinkoGameState> {

    private autoInterval?: NodeJS.Timer;

    constructor(props: any) {
        super(props);
        if (!props.parameters[0] || !props.parameters[1]) {
            window.location.href = 'https://dragonsden.gg/plinko/12/high'
            return;
        }
        const pr = props.parameters[1];
        const risk = pr.substring(0, 1).toUpperCase() + pr.toLowerCase().substring(1);
        this.state = {
            currentTab: 0,
            index: 0,
            lines: Number(props.parameters[0]),
            risk: risk,
            wagerAmount: props.currency == 'OSRS' ? 10000 : 0.10,
            lastMultipliers: [ ],
            winningsData: [{ time: new Date(), earnings: 0 }]
        }
        this.updateState = this.updateState.bind(this)
    }

    wager = async (amount: number, lines: number, risk: string) => get(backendBot, `/plinko/wager?&wager_amount=${amount}&wager_parameters=${lines}:${risk}`)

    updateState(data: any) {
        this.setState(data)
    }

    componentDidMount() {
    }

    getBetButton(type: number, mobile: boolean) {
        return (<button onClick={() => {
           if (this.state.autoing) {
               if (this.autoInterval) {
                   clearInterval(this.autoInterval)
               }
               this.setState({ betting: false, autoing: false })
               return;
           }
           if (this.state.betting) return
           this.setState({ betting: true }, () => {
               if (type == 1) {
                   this.setState({ autoing: true }, () => {
                       this.autoInterval = setInterval(() => {
                           this.placeBet()
                       }, 1000)
                   })
               } else {
                   this.placeBet()
               }
           })
                                    }}>{
                                    type === 1 ?
           ((this.state.autoing) ? 'Stop Autobet' : 'Start Autobet') :
           this.state.betting ? 'Betting...' : 'Bet'
        }</button>)
    }

    render() {
        return (
            <div className="Plinko">
                <ul className="GameContent">
                    <li className="Small">
                        <div className="PlinkoCanvasMobileResizable RollDiceCanvas">
                            {this.getBetButton(this.state.currentTab, false)}
                        </div>
                        <div className="GameTabs">
                            <Tabs padding={10} >
                                {/*@ts-ignore*/}
                                <div label="Manual" onTabClick={() => this.setState({currentTab: 0})}>
                                    {
                                        this.renderCrashOptions(0)
                                    }
                                </div>
                                {/*@ts-ignore*/}
                                <div label="Auto" onTabClick={() => this.setState({currentTab: 1})}>
                                    {
                                        this.renderCrashOptions(1)
                                    }
                                </div>
                            </Tabs>
                        </div>
                    </li>
                <li className="Large">
                    <div className="PlinkoCanvas" style={{ height: '100%', width: '100%' }}>
                        <PlinkoCanvas setDropBall={(db: any) => this.setState({ dropBall: db })} lines={this.state.lines} risk={this.state.risk} />
                    </div>
                </li>

                </ul>
                <div className="GameContentBar"></div>
                {this.state.earningsChart && gameModal(
                    this.props.currency,
                    this.state.earningsChart,
                    () => this.setState({ ...this.state, earningsChart: false }),
                    () => {
                        this.setState({ wins: 0, losses: 0, earnings: 0, winningsData: [{ time: new Date(), earnings: 0 }] })
                    },
                    this.state.wagered ?? 0,
                    this.state.earnings ?? 0,
                    this.state.wins ?? 0,
                    this.state.losses ?? 0,
                    this.state.earningsChart,
                    this.state.winningsData
                )}
            </div>);
    }

    renderCrashOptions(type: number) {
        return (<div>
            {/*@ts-ignore*/}
            <div width="730" className="GameCanvas">
                <div className="CanvasRow">
                    <div className="RowHeader">
                        <div className="Left">{formatBetAmountHeader(this.props.currency)}</div>
                        <div className="Right">{formatBetAmount(this.props.currency, this.state.wagerAmount)}
                            <div
                                className="ActionMini"
                                onClick={() => {
                                    this.setState({ earningsChart: !this.state.earningsChart })
                                }}
                            >
                                <FontAwesomeIcon icon={faChartLine} style={{ cursor: 'pointer' }}  />
                            </div>
                        </div>

                    </div>
                    <div className="RowHeader">
                        <div className="CanvasField">
                            <div className="AmountField">
                                {getAmountInput(this.props.currency, this.state.wagerAmount, this.props.balance, this.updateState)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="CanvasRow">
                    <div className="RowHeader">
                        <div className="Left">Risk</div>
                    </div>
                    <div className="RowHeader">
                        <div className="CanvasField">
                            <div className="AmountField">
                                <div className="Value ValueDropdown">
                                    <select defaultValue={this.state.risk} onChange={e => {
                                        window.location.href = `https://dragonsden.gg/plinko/${this.state.lines}/${e.target.value.toLowerCase()}`
                                    }}>
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="CanvasRow">
                    <div className="RowHeader">
                        <div className="Left">Rows</div>
                    </div>
                    <div className="RowHeader">
                        <div className="CanvasField">
                            <div className="AmountField">
                                <div className="Value ValueDropdown">
                                    <select defaultValue={this.state.lines} onChange={e => {
                                        window.location.href = `https://dragonsden.gg/plinko/${e.target.value}/${this.state.risk.toLowerCase()}`
                                    }}>
                                        {
                                            [8,9,10,11,12,13,14,15,16].map(val => {
                                                return <option value={`${val}`}>{val}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    type == 1 ? this.getAutomaticForm() : <div />
                }
                <div className="CanvasRow">
                    <div className="PlinkoCanvasDesktopResizable RollDiceCanvas">
                        {this.getBetButton(type, false)}
                    </div>
                </div>
            </div>
        </div>)
    }

    placeBet() {
        const previousBalance = this.props.balance;
        const previousWagered = this.state.wagered ?? 0;
        const amount = currencies.get(this.props.currency)!!.toWhole(this.state.wagerAmount)
//         this.wager(amount, this.state.lines, this.state.risk).then(r => {
//             if (r.roll == -1) {
//                 this.props.warn('Bet failed!')
//             } else {
//                 let stopAuto = {}
//                 if (this.state.stopOnMultiplier && Number(this.state.stopOnMultiplier)) {
//                     if (r.roll >= Number(this.state.stopOnMultiplier)) {
//                         if (this.autoInterval) {
//                             clearInterval(this.autoInterval)
//                         }
//                         stopAuto = { autoing: false }
//                     }
//                 }
//                 this.setState({betting: false, ...stopAuto }, () => {
//         this.setState({betting: false }, () => {
//                 this.state.dropBall(0.2)
//         });
//                     let earnings = r.newBalance - previousBalance;
//                     let wins = this.state.wins ?? 0;
//                     let losses = this.state.losses ?? 0;
//                     if (earnings > 0) {
//                         wins++;
//                     } else {
//                         losses++;
//                     }
//                     if (r.newBalance > 0) {
//                         this.props.setBalance(r.newBalance)
//                         this.state.winningsData.push({ time: new Date(), earnings: (this.state.earnings ?? 0) + earnings });
//                     }
//                     this.setState({
//                         ...this.state,
//                         wagered: previousWagered + amount,
//                         earnings: (this.state.earnings ?? 0) + earnings,
//                         wins: wins,
//                         losses: losses,
//                     });
//                 })
//
//             }
//         })
    }

    getAutomaticForm() {
        return (
            <div className="CanvasRow">
                <div className="RowHeader">
                    <div className="Left">Stop on Multiplier</div>
                </div>
                <div className="RowHeader">
                    <div className="CanvasField">
                        <div className="AmountField">
                            <div className="IconValue">
                                <div className="ProfitValue">
                                    <input type="number" step="0.01" min={1.1} max={100} onChange={e => this.setState({ stopOnMultiplier: Number(e.target.value) })} defaultValue={this.state.stopOnMultiplier} />
                                </div>
                                <div className="ProfitCurrency"><img src={`/assets/currencies/${currencies.get(this.props.currency)!!.img}`} className="Currency"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Plinko;
