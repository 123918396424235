import React, {Component} from 'react';
import CrashGameCanvas from "./CrashGameCanvas";

import './Crash.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import {cryptoDefaults, formatBetAmount, formatBetAmountHeader, getAmountInput} from "../wagerInput";
import {backendBot, get} from "../../Utility/httpClient";
import {currencies} from "../../Utility/currencies";
import {playGameSound} from "../../Utility/gameSounds";
import {gameModal} from "../gameModal";
import Tabs from "../../Components/Tabs/Tabs";
import {toRuneScapeFormatFromExact} from "../../Utility/utils";

type CrashState = {
    currentTab: number
    buttonState: number

    gameId: number
    gameTime: number
    wagerId: number
    wagerAmount: number
    cryptoWagerAmount?: number
    crashed: number | boolean
    stopOnMultiplier: number
    defaultTimer: number
    participants: Array<any>
    roundHistory: Array<number>
    rolling?: boolean
    loaded?: boolean
    withdrawn?: number
    autoing?: boolean
    skipUpdate?: boolean

    /** Earnings chart */
    earningsChart?: boolean
    wagered?: number;
    earnings: number;
    wins?: number;
    losses?: number;
    winningsData: any;
}

const LOADING_GAME = 0, MISSED_ROUND = 1, BETTING_PHASE = 2, WITHDRAWING_PHASE = 3;

class Crash extends Component<any, CrashState> {

    private autoInterval?: NodeJS.Timer;

    constructor(props: any) {
        super(props);
        this.state = {
            currentTab: 0,
            buttonState: LOADING_GAME,
            gameId: -1,
            gameTime: 0,
            wagerId: -1,
            wagerAmount: props.currency == 'OSRS' ? 10000 : 0.10,
            earnings: 0,
            participants: [],
            crashed: -1,
            stopOnMultiplier: 1.5,
            defaultTimer: -1,
            roundHistory: [],
            winningsData: [{ time: new Date(), earnings: 0 }]
        }
        this.placeBet = this.placeBet.bind(this)
        this.setGameId = this.setGameId.bind(this)
        this.setWagerId = this.setWagerId.bind(this)
        this.getParticipants = this.getParticipants.bind(this)
        this.setParticipants = this.setParticipants.bind(this)
        this.setCrashed = this.setCrashed.bind(this)
        this.addRoundToHistory = this.addRoundToHistory.bind(this)
        this.updateState = this.updateState.bind(this)
    }

    current = async () => get(backendBot, `/api/crash-state`)
    placeWager = async (amount: number, gameId: number) => get(backendBot, `/api/crash?player=${this.props.username}&wager_amount=${amount}&wager_parameters=${gameId}:wager`)
    withdrawWager = async () => get(backendBot, `/api/crash?player=${this.props.username}&wager_amount=0&wager_parameters=${this.state.gameId}:withdraw`)

    setGameId(gameId: number) {
        this.setState({gameId: gameId, gameTime: Date.now(), withdrawn: -1 })
    }

    addRoundToHistory(value: number) {
        let roundHistory = [ value ]
        this.state.roundHistory.map(h => roundHistory.push(h))
        this.setState({roundHistory: roundHistory})
    }

    setCrashed(crashed: number, participants: Array<any> | undefined) {
        console.log("Setting crashed " + crashed)
        if (crashed != -1 && participants) {
            participants.map(p => {
                            console.log("participant !!")
                            console.log(p)
                            console.log(this.props.username)
                            console.log("participant !!")
                            if (p.name == this.props.username) {
                                this.wagerWon(p.multiplier > 0.1, (p.amount ?? 0) * (p.multiplier ?? 0))
                            }
                        })
        } else {
            if (this.state.wagerId == -1 && this.state.autoing) {
                this.placeBet()
            }
        }
        this.setState({crashed: crashed, buttonState: BETTING_PHASE, wagerId: -1, gameId: -1, gameTime: 0 })
    }

    wagerWon(won: boolean, earnings: number) {
        let wins = this.state.wins ?? 0;
        let losses = this.state.losses ?? 0;
        if (won) {
            wins++;
        } else {
            losses++;
        }
        this.state.winningsData.push({ time: new Date(), earnings: (this.state.earnings ?? 0) + earnings });
        this.setState({
            ...this.state,
            earnings: (this.state.earnings ?? 0) + earnings ?? 0,
            wins: wins,
            losses: losses,
        });
    }

    updateState(data: any) {
        this.setState(data)
    }

    setWagerId(wagerId: number) {
        this.setState({wagerId: wagerId, buttonState: wagerId == -1 ? BETTING_PHASE : WITHDRAWING_PHASE })
    }

    setParticipants(participants: Array<any>) {
      //  if (this.state.participants?.length > 0 && participants.length == 0) {
          //
        //}
        this.setState({participants: participants})
    }

    getParticipants() {
        return this.state.participants
    }

    componentDidMount() {
        this.setState({buttonState: MISSED_ROUND, loaded: true})
        cryptoDefaults(this.props.currency, this.updateState);
        this.current().then(r => {
            if (Number(r.state) > -1) {
                // @ts-ignore
                this.state.defaultTimer = Number(r.state)
                r.currentBets.map((b: any) => {
                    if (b.name == this.props.username) {
                        // @ts-ignore
                        this.state.wagerId = b.multiplier > 0 ? -1 : 1
                        // @ts-ignore
                        this.state.crashed = -1
                    }
                })
            }
            this.setState({gameId: r.gameId, roundHistory: r.roundHistory, participants: r.currentBets})
        })
    }

    render() {
        return (
        <div className="Crash">
            <ul className="GameContent CrashGameContent">
                <li className="Small">
                    <div className="PlinkoCanvasMobileResizable RollDiceCanvas">
                        {
                            this.getBetButton(this.state.currentTab)
                        }
                    </div>
                    <div className="GameTabs">
                        <Tabs padding={10}>
                            {/*@ts-ignore*/}
                            <div label="Manual" onTabClick={() => this.setState({currentTab: 0})}>
                                {
                                    this.renderCrashOptions(0)
                                }
                            </div>
                            {/*@ts-ignore*/}
                            <div label="Auto" onTabClick={() => this.setState({currentTab: 1})}>
                                {
                                    this.renderCrashOptions(1)
                                }
                            </div>
                        </Tabs>
                    </div>
                </li>
                <li className="Large">
                    <div className="ChartCanvas" style={{ height: '100%', width: '100%' }}>
                        <CrashGameCanvas setParentGameId={this.setGameId}
                                         getParticipants={this.getParticipants}
                                         setParticipants={this.setParticipants}
                                         setParentCrashed={this.setCrashed}
                                         addRoundToHistory={this.addRoundToHistory}
                        />
                    </div>
                    <ul className="CrashRoundHistory">
                        {
                            this.state.roundHistory.map(score => {
                                return <li className={score > 2 ? "Positive" : ""}>{score}</li>
                            })
                        }
                    </ul>
                </li>

            </ul>
            <div className="GameContentBar"></div>
            {this.state.earningsChart && gameModal(
                this.props.currency,
                this.state.earningsChart,
                () => this.setState({ ...this.state, earningsChart: false }),
                () => {
                    this.setState({ wins: 0, losses: 0, earnings: 0, winningsData: [{ time: new Date(), earnings: 0 }] })
                },
                this.state.wagered ?? 0,
                this.state.earnings ?? 0,
                this.state.wins ?? 0,
                this.state.losses ?? 0,
                this.state.earningsChart,
                this.state.winningsData
            )}
        </div>);
    }

    isBetDisabled(type: number): boolean {

         if (type == 1) {
            return false
         } else {
             if (this.state.autoing) {
                return false
             }

            if (this.state.buttonState == BETTING_PHASE) {
                if (this.state.gameId == -1 && this.state.wagerId == -1) {
                    if (!this.getParticipants().some(a => a.name == this.props.username.replace(' ', '_'))) {
                        return false
                    }
                }
            } else if (this.state.buttonState == WITHDRAWING_PHASE) {
                if (this.state.wagerId != -1 && this.state.gameId != -1) {
                    return false
                }
            }
         }

        return true
    }

    getBetButton(type: number) {
        var text = "Loading..."
        const betClick = () => {
            if (this.isBetDisabled(type)) return;
            if (this.state.autoing) {
                if (this.autoInterval) {
                    clearInterval(this.autoInterval)
                }
                this.setState({rolling: false, autoing: false})
                return;
            }
            if (this.state.rolling) return;
            this.setState({rolling: true}, () => {
                if (type == 1) {
                    this.setState({autoing: true}, () => {
                        this.autoInterval = setInterval(() => {
                            if (this.state.crashed != -1 && this.state.wagerId == -1) {
                                this.placeBet()
                            } else if (this.state.gameId != -1 && this.state.wagerId != -1) {
                                let time_elapsed = (Date.now() - this.state.gameTime) / 1000.0
                                let multiplier = 1.0024 * Math.pow(1.0718, time_elapsed)
                                if (multiplier >= this.state.stopOnMultiplier) {
                                    this.placeBet()
                                }
                            }
                        }, 500)
                    })
                } else {
                    this.placeBet()
                }
            })
        }

        if (type == 1) {
            if (this.state.buttonState != LOADING_GAME) {
                if (this.state.autoing) {
                    text = "Stop Autoing"
                } else {
                    text = "Automate rounds"
                }
            }
        } else if (this.state.buttonState != LOADING_GAME) {

            if (this.state.buttonState == MISSED_ROUND) {
                text = "Waiting..."
            } else if (this.state.buttonState == BETTING_PHASE) {
                if (this.state.gameId == -1) {
                    if (this.state.wagerId == -1) {
                        text = "Bet"
                    } else {
                        text = "Waiting..."
                    }
                } else {
                    text = "Waiting..."
                }
            } else if (this.state.buttonState == WITHDRAWING_PHASE) {
                if (this.state.wagerId != -1) {
                    text = "Withdraw"
                } else {
                    text = "Waiting..."
                }
            }

        }

        if (type == 0 && this.state.autoing) {
            text = "Auto-Betting"
        }

        return (<button className={this.isBetDisabled(type) ? "Disabled" : ""} onClick={betClick}>{text}</button>)
    }

    renderCrashOptions(type: number) {
        return (<div>
            {/*@ts-ignore*/}
            <div width="730" className="GameCanvas CrashGameCanvas">
                <div className="CanvasRow">
                    <div className="RowHeader">
                        <div className="Left">{formatBetAmountHeader(this.props.currency)}</div>
                        <div className="Right">{formatBetAmount(this.props.currency, this.state.wagerAmount)}
                            <div
                                className="ActionMini"
                                onClick={() => {
                                    this.setState({ earningsChart: !this.state.earningsChart })
                                }}
                            >
                                <FontAwesomeIcon icon={faChartLine} style={{ cursor: 'pointer' }}  />
                            </div>
                        </div>
                    </div>
                    <div className="RowHeader">
                        <div className="CanvasField">
                            <div className="AmountField">
                                {getAmountInput(this.props.currency, this.state.wagerAmount, this.props.balance, this.updateState)}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    type == 1 ? this.getAutomaticForm() : <div/>
                }
                <div className="CanvasRow">
                    <div className="PlinkoCanvasDesktopResizable RollDiceCanvas">
                        {
                            this.getBetButton(type)
                        }
                    </div>
                </div>
                <div className="CanvasRow">
                    <div className="RowHeader">
                        <div className="Left">Players</div>
                    </div>
                    <div className="CrashParticipants">
                        <ul className="Headers">
                            <li>Name</li>
                            <li>Amount</li>
                            <li>Multiplier</li>
                        </ul>
                        {
                            this.state.participants.map((p: any) => {
                                return (<ul>
                                    <li>{p.name.replace('_', ' ')}</li>
                                    <li>
                                            <span className="dRrTFD positive">
                                                <img width={20}
                                                    src={`/currencies/${currencies.get(p.currency ?? 'OSRS')!!.img}`} className="eiogy Currency" />
                                                <span className="ixoRjG">{toRuneScapeFormatFromExact(Number(p.amount))}</span>
                                            </span>
                                    </li>
                                    <li>
                                        <span className="ixoRjG">{p.multiplier ? Number(p.multiplier).toFixed(2) + 'x' : '-'}</span>
                                    </li>
                                </ul>)
                            })
                        }
                    </div>
                </div>
            </div>
        </div>)
    }

    placeBet() {
        playGameSound("/_crash/bet.mp3")
        if (this.state.wagerId != -1) {
            this.withdrawWager().then(r => {
                if (r.newBalance != -1) {
                    this.setState({gameId: -1})
                    //@ts-ignore
                    this.state.rolling = false
                    //@ts-ignore
                    this.state.wagerId = -1
                    //@ts-ignore
                    this.state.withdrawn = 1

                }
            })
        } else {
            const previousWagered = this.state.wagered ?? 0;
            const amount = currencies.get(this.props.currency)!!.toWhole(this.state.wagerAmount)
            this.placeWager(amount, this.state.gameId).then(r => {
                this.setState({
                    ...this.state,
                    wagerId: r.id,
                    rolling: false,
                    buttonState: r.id == -1 ? BETTING_PHASE : WITHDRAWING_PHASE,
                    wagered: previousWagered + this.state.wagerAmount,
                });
            })
        }
    }

    getAutomaticForm() {
        return (<div className="CanvasRow">
            <div className="RowHeader">
                <div className="Left">Stop on Multiplier</div>
            </div>
            <div className="RowHeader">
                <div className="CanvasField">
                    <div className="AmountField">
                        <div className="IconValue">
                            <div className="ProfitValue">
                                <input type="number" step="0.01" min={1.1} max={100} onChange={e => this.setState({stopOnMultiplier: Number(e.target.value)})} defaultValue={this.state.stopOnMultiplier} />
                            </div>
                            <div className="ProfitCurrency"><img
                                src={`/currencies/${currencies.get(this.props.currency ? this.props.currency : 'USD')!!.img}`} className="Currency"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default Crash;