import {ChipComponentProps, getChipValue} from "./rouletteTypes";
import {toRuneScapeFormatFromDecimal} from "../../Utility/currencies";

export const ChipComponent = (props: ChipComponentProps) => {
    const {
        ballScreen,
        index
    } = props;
       
    const totalChipsInPosition = ballScreen[index].chips.reduce((partialSum, a) => partialSum + a.quantity, 0)
    let totalChipsValues = 0
    ballScreen[index].chips.map(chip => {
        totalChipsValues += getChipValue(chip.id) * chip.quantity
    })
    let chipSelector = 'ball-1'
    let textSelector = ''
    if (totalChipsValues >= 100000) {
        chipSelector = 'ball-9'
        textSelector = 'text-green'
    } else if (totalChipsValues >= 10000) {
        chipSelector = 'ball-7'
    } else if (totalChipsValues >= 1000) {
        chipSelector = 'ball-6'
    }
    return (
        <>
            {
         [...new Array(totalChipsInPosition > 4 ? 4 : totalChipsInPosition)].map((ele, i) => {
             return <div style={{ top: - (i) * 5 + "px" }} className={chipSelector + ' ' + textSelector +' ChipsOnBoard'}>
                 <p>{toRuneScapeFormatFromDecimal(totalChipsValues)}</p>
             </div>
         })
            }
        </>
    )
}