export type AccountEvent = {
    event: string;
    target: string;
    value: string;
};

export const accountEvents = async (
    player: string,
    balanceChangedCallback: (username: string, balance: number) => void,
    // currencyChanged: (currency: string) => void
) => {
    const token = localStorage.getItem('bearer');
    const client = new EventSource(`https://api.dragonsden.gg/general/account-feed?player=${player}&token=${token}`);
    client.addEventListener("account_update", jmsevent => {
        if (jmsevent.data == 'keep-alive') return;
        console.log("BALANCE UPDATE " + jmsevent.data)
        let eventData: AccountEvent = JSON.parse(jmsevent.data)
        switch (eventData.event) {
            case 'balance_amount_update': {
                let values = eventData.value.split(':')
                let newBalance = Number(values[0])
                balanceChangedCallback(eventData.target, newBalance);
                break
            }
            case 'balance_currency_update': {
                // const newCurrency = eventData.value
                // currencyChanged(newCurrency)
                break
            }
        }
    })
}