import React, {useEffect, useState} from "react";
import '../Assets/CSS/Admin.scss';
import {AccountVerbose, BotDTO, EarningsDay, Player, PlayerDetails, PlayerVerbose, WagerDTO} from "../API/api";
import {axiosGet,axiosPost} from "../Utility/httpClient";
import {makeStyles} from "@material-ui/core/styles";
import {ArrowBack} from "@material-ui/icons";
import {DiscordUsersTable} from "../Components/DiscordUsersTable";
import {Chart} from "../Components/Chart";
import {formatDateTimeYear, toRuneScapeFormatFromExact} from "../Utility/utils";
import moment from "moment";
import {Footer} from "../Components/Footer";
import ConfirmActionModal from "../Components/ConfirmActionModal";
import {useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#202429 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
    chip: {
        color: 'white !important',
        background: '#121418 !important',
        backgroundColor: '#121418 !important',
        border: '1px solid #2a2e38 !important',
        cursor: 'pointer !important'
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    chipSelected: {
        backgroundSize: 'cover',
        height: '50px',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
        cursor: 'pointer !important',
        backgroundColor: '#080808',
        border: '3px solid #886CFF !important',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
}));

export const dialogStyles = {
    backgroundColor: '#1a1d23',
    color: 'white',
    padding: '25px',
    boxShadow: 'none',
};

const getAccount = async (username: string): Promise<AccountVerbose> => axiosGet(`/admin/account?username=${username.replaceAll(" ", "_")}`)
const resetRakeback = async (username: string): Promise<AccountVerbose> => axiosPost(`/admin/account-rakeback-reset`, {username: username})
const getPlayerEarnings = async (username: string): Promise<Array<EarningsDay>> => axiosGet(`/admin/account-earnings?username=${username.replaceAll(" ", "_")}`)

export type BotsProps = {
    userDetails: PlayerDetails | undefined
};

export const AdminAccountView: React.FC<BotsProps> = ({ userDetails }) => {
    const params = useParams();
    const [user, setUser] = React.useState<Player|undefined>(undefined)
    const [playerEarnings, setPlayerEarnings] = React.useState<Array<EarningsDay>>([])
    const [playerDetails, setPlayerDetails] = React.useState<AccountVerbose|undefined>(undefined)
    const [activeIndex, setActiveIndex] = React.useState<number>(0)
    const [confirmAction, setConfirmAction] = useState(() => () => console.log("default ooops"));
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (userDetails) {
            getAccount(params.id!!).then(r => {
                setPlayerDetails(r)
                getPlayerEarnings(r.username).then(r => setPlayerEarnings(r))
            })
        }
    }, [userDetails])

    function getView(user: Player | undefined, activeIndex: number, setActiveIndex: any, setUser: any): JSX.Element {
        if (playerDetails) {
            return (<>
                <div className="Metrics-container">
                    <div className="Section-heading">
                        <h4 style={{textAlign: 'left'}}><ArrowBack onClick={() => {
                            setUser(undefined)
                            window.location.href = "https://dragonsden.gg/admin/accounts"
                        }} width={80} style={{marginRight: '10px'}}/>{playerDetails.username}</h4>
                    </div>
                </div>

                <div className="homeWidgets">
                    <div className="widgetSm">
                        <ul className="widgetSmList">
                            <ul>
                                <li>Username</li>
                                <li>{playerDetails.username}</li>
                            </ul>
                            <ul>
                                <li>Total Balance</li>
                                <li>{playerDetails.balance}</li>
                                {/*<li>{formatDateTimeYear(playerDetails.since)}</li>*/}
                            </ul>
                            <ul>
                                <li>Total Rakeback</li>
                                <li>{playerDetails.rakeback}</li>
                                {/*<li>{moment(playerDetails.seen).fromNow()}</li>*/}
                            </ul>
                            <ul>
                                <li>Profiles</li>
                                <li>{playerDetails.profiles.length}</li>
                            </ul>
                            <ul>
                                <li>Actions</li>
                            </ul>
                            <ul>
                                <li>
                                    <br/>
                                    <button onClick={() => {
                                        setConfirmAction(() => () => {
                                            resetRakeback(playerDetails.username).then(response => {
                                                setPlayerDetails(response)
                                            })
                                        })
                                        setOpenConfirm(true)
                                    }
                                    }>Reset Rakeback All Profiles
                                    </button>
                                    <br/>
                                    <br/>
                                </li>
                            </ul>
                            <ul>
                                <li>OSRS Profiles</li>
                            </ul>
                            <ul>
                                <li>
                                    <br/>
                                    <select>
                                        {
                                            playerDetails.profiles.map(p => <option>{p.username}</option>)
                                        }
                                    </select>
                                </li>
                            </ul>

                        </ul>
                    </div>

                    <div className="widgetSm" style={{minWidth: '800px'}}>
                        <span className="widgetSmTitle">Overall Earnings</span>
                        <ul className="widgetSmList">
                            <Chart data={playerEarnings ?? []}/>
                        </ul>
                    </div>


                </div>


                <ConfirmActionModal
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                    action={confirmAction}
                />

            </>)
        }
        return (<div>

            <div className="Section-heading">
                <h4>Loading...</h4>
            </div>

        </div>);
    }

    return (<>
        <div className="App-contents">
            <div className="Landing-content">
                <br/><br/>
                {getView(user, activeIndex, setActiveIndex, setUser)}
                <Footer/>
            </div>
        </div>
    </>)

}