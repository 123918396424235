import React, {Component} from "react";

import "./Roulette.scss";
import Tabs from "../../Components/Tabs/Tabs";
import {currencies} from "../../Utility/currencies";
import RouletteGameCanvas from "./RouletteGameCanvas";
import {backendBot, get} from "../../Utility/httpClient";
import {Box, Button, Modal, Typography} from "@mui/material";
import {RouletteState} from './rouletteTypes';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartLine} from '@fortawesome/free-solid-svg-icons';
import {gameModal} from "../gameModal";
import {playGameSound} from "../../Utility/gameSounds";
import {cryptoDefaults, formatBetAmount, formatBetAmountHeader, getAmountInput} from "../wagerInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

let cnd = 0;

const chip_list: Array<string> = [
  "10K",
  "100K",
  "1M",
  "10M",
  "100M",
  "100K",
  "1M",
  "10M",
  "100M",
  "1B",
];

export type bordeDataProp = {
  chipId: string,
  chipPosition: number,
  chipCount: number
}

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

class Roulette extends Component<any, RouletteState> {
  private rouletteRef?: React.Ref<any>;
  private autoInterval?: NodeJS.Timer;
  private boardData?: Array<any>;

  constructor(props: any) {
    super(props);
    this.state = {
      wagerAmount: 0,
      bet_number: null,
      chip_value: "0",
      current_chip: 1,
      onWin: 0,
      onWinIncreaseBy: 0,
      onLoss: 0,
      onLossIncreaseBy: 0,
      stopOnLoss: 0,
      stopOnWin: 0,
      open: false,
      onHistory: false,
      balance: 0,
      sessionProfit: 0,
      previousResult: null,
      earnings: 0,
      autoing: false,
      wonPrevious: false,
      wager: 0,
      wagered: 0,
      winningsData: [{ time: new Date(), earnings: 0 }],
      profit: 0,
      wins: 0,
      losses: 0,
      betRoll_list: [],
      startingBalance: 0,
      onAnimation: true,

      onModal: false,
      spinning: false,
      selectChipIndex: 0,

      /* Multiply buttons */
      doubleChip: false,
      divide: false,

      currentChip: 0,
      gain: 0,
      percent: 0,
      order: '1st', //2nd 3rd 4th 5th...
      prize: 0
    };
    this.setStateOfParent.bind(this);
    this.handleClose.bind(this);
    this.handleCurrentChip.bind(this);
    this.handleHistory.bind(this);
    this.changeBorderData.bind(this);
    this.updateState = this.updateState.bind(this)
  }
  updateState(data: any) {
    this.setState(data)
  }

  componentDidMount() {
    cryptoDefaults(this.props.currency, this.updateState);
    if (!this.state.startingBalance) {
      this.setState({ startingBalance: this.props.balance })
    }
  }

  componentDidUpdate(prevProps: {}, prevState: RouletteState) {

    if (this.state.earnings !== prevState.earnings) {
      this.setState({ prize: 1 });
      setTimeout(() => this.setState({ prize: 0 }), 10);
    }
  }

  objectToQueryString(objArray: any): string {
    const encoded = encodeURIComponent(JSON.stringify(objArray));
    return `${encoded}`;
  }

  wager = async (amount: number) =>
    get(backendBot,
      `/game/roulette/wager?&wager_amount=${amount}&wager_parameters=${this.objectToQueryString(this.boardData)}`
    );

  setStateOfParent = (newAmount: number) => {
    this.setState({ ...this.state, wagerAmount: newAmount });
  };

  handleCurrentChip = (chip: number) => {
    this.setState({
      currentChip: chip
    })
  };

  handleClose = () => this.setState({ ...this.state, open: false });

  handleHistory = (ele: boolean) => this.setState({ ...this.state, onHistory: ele })

  changeBorderData = (ele: Array<bordeDataProp>) => {
    this.boardData = ele;
  }

  initialChipValue = () => {
    this.setState({ chip_value: "0" })
  }

  render() {
    return (
      <div className="Roulette grey_dark">
        <ul className="GameContent RouletteGameContent">
          <li className="Small">
            <div className="GameTabs">
              <Tabs padding={10} handleHistory={this.handleHistory}>
                {/*@ts-ignore*/}
                <div label="Manual" className="grey_color">
                  {this.renderCrashOptions(0)}
                </div>
                {/*@ts-ignore*/}
                <div label="Auto" className="grey_color">
                  {this.renderCrashOptions(1)}
                </div>
              </Tabs>
            </div>
          </li>
          <li
            className="Large"
            style={{ padding: "1%", backgroundColor: "rgba(16, 36, 46, 0.7)" }}
          >
            <RouletteGameCanvas
              ref={this.rouletteRef}
              setState={this.setStateOfParent}
              timer={1000}
              bet_number={this.state.bet_number ?? this.state.bet_number}
              chip_value={this.state.chip_value}
              state={this.state.onHistory}
              setBorderData={this.changeBorderData}
              onAnimation={this.state.onAnimation}
              chip_index={this.state.selectChipIndex}
              tabState={this.props.tabs}
              doubleChip={this.state.doubleChip}
              divide={this.state.divide}
              handleCurrentChip={this.handleCurrentChip}
            />
          </li>
        </ul>
        <div className="GameContentBar"></div>
        {this.state.onModal && gameModal(
            this.props.currency,
            this.state.onModal,
            () => this.setState({ ...this.state, onModal: false }),
            () => {
              this.setState({ wins: 0, losses: 0, earnings: 0, winningsData: [{ time: new Date(), earnings: 0 }] })
            },
            this.state.wagered,
            this.state.earnings,
            this.state.wins ?? 0,
            this.state.losses ?? 0,
            this.state.prize === 0,
            this.state.winningsData
        )}

      </div>
    );
  }

  renderCrashOptions(type: number) {
    let buttonState = "";
    const currencie = currencies.get(this.props.currency)!!
    const crypto = currencie.cryptoCurrency
    let img = currencie.img
    if (crypto) {
      img = currencies.get('USD')!!.img
    }
    return (
      <div>
        {/*@ts-ignore*/}
        <div width="730" className="GameCanvas RouletteGameCanvas">
          <div className="CanvasRow">
            <div className="ChipValue">
              <div className="Left">
                <div>Chips</div>
                <div className="chipValue_left">
                  <FormGroup>
                    <FormControlLabel control={
                      <Switch {...label} size="small" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({ onAnimation: e.target.checked })
                      }} checked={this.state.onAnimation} />
                    } label="Animation" style={{ color: 'white' }} />
                  </FormGroup>
                </div>
              </div>
              <div className="board grey_color1">
                <div
                  className="left_arrow"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      current_chip:
                        (this.state.current_chip - 1) % 3
                          ? (this.state.current_chip - 1) % 3
                          : 1,
                    })
                  }
                >
                  {"<"}
                </div>
                <div className="chip">
                  {chip_list.map((ele, id) =>
                    id >= (this.state.current_chip - 1) * 5 &&
                      this.state.current_chip * 5 > id ? (
                      <div
                        className={`chip_area`}
                        style={{ display: "flex" }}
                        id={id.toString() + "-"}
                        key={id}
                        onClick={() => {

                          this.setState({ ...this.state, chip_value: ele });
                          chip_list.map((i, ind) => {
                            if (id === ind) {
                              this.setState(
                                { selectChipIndex: id },
                                () => {
                                  (document.getElementById("" + id.toString() + "-")!.className =
                                    "chip_area select")
                                }
                              )
                            }
                            else {
                              (document.getElementById("" + ind.toString() + "-")!.className =
                                "chip_area")
                            }
                          }
                          );
                        }}
                      >
                        <img
                          src={`/_roulette/chip${id < 5 ? id : id - 4}.svg`}
                          className="chip_pad"
                        />
                        <div
                          className="chip_text"
                          style={
                            (id < 3 || (id > 4 && id - 5 < 2)) ? { color: "white" } : { color: "#01fe81" }
                          }
                        >
                          {ele}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`chip_area`}
                        style={{ display: "none" }}
                        id={id.toString() + "-"}
                        key={id}
                        onClick={() => {
                          this.setState({ ...this.state, chip_value: ele });
                          chip_list.map((i, ind) => {
                            if (id === ind) {
                              this.setState(
                                { selectChipIndex: id },
                                () => {
                                  (document.getElementById("" + id.toString() + "-")!.className =
                                    "chip_area select")
                                }
                              )
                            }
                            else {
                              (document.getElementById("" + ind.toString() + "-")!.className =
                                "chip_area")
                            }
                          }
                          );
                        }}
                      >
                        <img
                          src="/_roulette/1M.c5e35b7f002b6e6980dc9ff4188a9be7.svg"
                          className="chip_pad"
                        />
                        <div className="chip_text">{ele}</div>
                      </div>
                    )
                  )}
                </div>
                <div
                  className="right_arrow"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      current_chip:
                        (this.state.current_chip + 1) % 3
                          ? (this.state.current_chip + 1) % 3
                          : 2,
                    })
                  }
                >
                  {">"}
                </div>
              </div>
            </div>
            <div className="RowHeader">
              <div className="Left">{formatBetAmountHeader(this.props.currency)}</div>
              <div className="Right">{formatBetAmount(this.props.currency, this.state.wagerAmount)}
                <div
                    className="ActionMini"
                    onClick={() => {
                      this.setState({ onModal: !this.state.onModal })
                    }}
                >
                  <FontAwesomeIcon icon={faChartLine} style={{ cursor: 'pointer' }}  />
                </div>
              </div>
            </div>
            <div className="RowHeader">
              <div className="CanvasField">
                <div className="AmountField Roulette">

                  <div className="Value">
                    <div className="ValueIcon" key={"wagered-" + this.state.wagerAmount}>
                      <span>{currencies.get(this.props.currency)!!.symbol}{currencies.get(this.props.currency)!!.format(this.state.wagerAmount)}</span>
                      <span>{}</span>
                    </div>
                    <img src={`/currencies/${currencies.get(this.props.currency)!!.img}`} className="Currency"/>
                  </div>
                  <div className="Action" onClick={() => {
                    this.setState({
                      wagerAmount: this.state.wagerAmount / 2,
                      divide: true
                    });
                    setTimeout(() => this.setState({ divide: false }), 100);
                  }}>½
                  </div>
                  <a className="ValueDivider"></a>
                  <div className="Action" onClick={() => {
                    if (
                        this.state.wagerAmount > 0 &&
                        this.state.wagerAmount * 2 < this.props.balance
                    ) {
                      this.setState({
                        wagerAmount: this.state.wagerAmount * 2,
                        doubleChip: true,
                      });
                    }
                    setTimeout(() => this.setState({ doubleChip: false }), 100);
                  }}>2x
                  </div>
                </div>
              </div>
            </div>
          </div>
          {type === 1 ? this.getAutomaticForm() : <div />}
          <div className="CanvasRow">
            <div className="RollDiceCanvas">
              <button className={buttonState} onClick={() => {
                if (this.state.wagerAmount <= 0) {
                  this.props.warn("You do not have any chips on the board.");
                  return;
                }
                if (this.state.wagerAmount < 0.02 || this.state.chip_value[this.state.chip_value.length - 1] == 'k') {
                  this.props.warn("Must have at least 20k (0.02) on the board to place a bet.");
                  return;
                }
                if (this.state.autoing) {
                  if (this.autoInterval) {
                    clearInterval(this.autoInterval);
                  }
                  this.setState({ spinning: false, autoing: false });
                  return;
                }
                if (this.state.spinning) return;
                this.setState({ spinning: true }, () => {
                  if (type === 1) {
                    this.setState({ onModal: true })
                    this.setState({ autoing: true }, () => {
                      if (this.state.onAnimation) {
                        this.placeBet();
                        this.autoInterval = setInterval(() => {
                          this.placeBet();
                        }, 4000);
                      } else {
                        this.placeBet();
                        this.autoInterval = setInterval(() => {
                          this.placeBet();
                        }, 1000);
                      }
                    });

                  } else {
                    this.placeBet();
                  }
                });
              }}>
                {
                  type === 1 ?
                    ((this.state.autoing) ? 'Stop Autobet' : 'Start Autobet') :
                    this.state.spinning ? 'Stop' : 'Spin'
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  placeBet() {
    const previousBalance = this.props.balance;
    this.setState({...this.state, bet_number: 21, spinning: false})
//     this.wager(this.state.cryptoWagerAmount ?? this.state.wagerAmount).then((r) => {
//       if (r.roll === -1) {
//         this.props.warn('Bet failed!')
//         clearInterval(this.autoInterval);
//         return;
//       }
//       playGameSound("/_roulette/spinning.6587b785.mp3")
//
//       let earnings = r.newBalance - previousBalance;
//       let wins = this.state.wins ?? 0;
//       let losses = this.state.losses ?? 0;
//       if (earnings > 0) {
//         wins++;
//       } else {
//         losses++;
//       }
//       if (r.newBalance > 0) {
//         this.props.setBalance(r.newBalance)
//         this.state.winningsData.push({ time: new Date(), earnings: this.state.earnings + earnings, wager: this.state.wagerAmount });
//       }
//       this.setState({
//         ...this.state,
//         spinning: false,
//         bet_number: r.roll,
//         earnings: this.state.earnings + earnings,
//         wins: wins,
//         losses: losses,
//       });
//     });
  }

  getAutomaticForm() {
    return (
      <div>
        <div className="CanvasRow">
          <div className="RowHeader">
            <div className="Left">Stop on Multiplier</div>
          </div>
          <div className="RowHeader">
            <div className="CanvasField">
              <div className="AmountField">
                <div className="IconValue">
                  <div className="ProfitValue">
                    <input
                      type="number"
                      step="0.01"
                      min={1.1}
                      max={100}
                      onChange={(e) =>
                        this.setState({
                          stopOnMultiplier: Number(e.target.value),
                        })
                      }
                      defaultValue={this.state.stopOnMultiplier}
                    />
                  </div>
                  <div className="ProfitCurrency">

                    <img src={`/assets/currencies/${currencies.get(this.props.currency)!!.img}`} className="Currency" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="LeftHalf MobileHalf">
            <div className="RowHeader">
              <div className="Left">On Win</div>
            </div>
            <div className="RowHeader">
              <div className="CanvasField">
                <div className="AmountField AmountFieldBigger">
                  <div
                    className={
                      this.state.onWin == 0
                        ? "AutoAction SelectedAction"
                        : "AutoAction"
                    }
                    onClick={() => this.setState({ onWin: 0 })}
                  >
                    Reset
                  </div>
                  <div
                    className={
                      this.state.onWin == 1
                        ? "AutoAction SelectedAction"
                        : "AutoAction"
                    }
                    onClick={() => this.setState({ onWin: 1 })}
                  >
                    Increase by
                  </div>
                  <div className="AutoActionValue">
                    <div className="ValueIcon">
                      <input
                        type="number"
                        step="1"
                        min={1}
                        max={10000}
                        onChange={(e) =>
                          this.setState({
                            onWin: 1,
                            onWinIncreaseBy: Number(e.target.value),
                          })
                        }
                        defaultValue={this.state.onWinIncreaseBy}
                      />
                    </div>
                    <img
                      src="/assets/games/online/dice/percent.svg"
                      className="Currency"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="RightHalf MobileHalf">
            <div className="RowHeader">
              <div className="Left">On Loss</div>
            </div>
            <div className="RowHeader">
              <div className="CanvasField">
                <div className="AmountField AmountFieldBigger">
                  <div
                    className={
                      this.state.onLoss == 0
                        ? "AutoAction SelectedAction"
                        : "AutoAction"
                    }
                    onClick={() => this.setState({ onLoss: 0 })}
                  >
                    Reset
                  </div>
                  <div
                    className={
                      this.state.onLoss == 1
                        ? "AutoAction SelectedAction"
                        : "AutoAction"
                    }
                    onClick={() => this.setState({ onLoss: 1 })}
                  >
                    Increase by
                  </div>
                  <div className="AutoActionValue">
                    <div className="ValueIcon">
                      <input
                        type="number"
                        step="1"
                        min={1}
                        max={100}
                        onChange={(e) =>
                          this.setState({
                            onLoss: 1,
                            onLossIncreaseBy: Number(e.target.value),
                          })
                        }
                        defaultValue={this.state.onLossIncreaseBy}
                      />
                    </div>
                    <img
                      src="/assets/games/online/dice/percent.svg"
                      className="Currency"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CanvasRow Inline Padded">
          <div className="LeftHalf">
            <div className="RowHeader">
              <div className="Left">Stop on Profit</div>
              <div className="Right">
                {currencies.get(this.props.currency)!!.symbol}0.00
              </div>
            </div>
            <div className="RowHeader">
              <div className="CanvasField">
                <div className="AmountField">
                  <div className="IconValue">
                    <div className="ProfitValue">
                      <input
                        type="number"
                        step=".000001"
                        min={0}
                        max={100}
                        onChange={(e) =>
                          this.setState({ stopOnWin: Number(e.target.value) })
                        }
                        defaultValue={this.state.stopOnWin}
                      />
                    </div>
                    <div className="ProfitCurrency">
                      <img
                        src={`/assets/currencies/${currencies.get(this.props.currency)!!.img
                          }`}
                        className="Currency"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="RightHalf">
            <div className="RowHeader">
              <div className="Left">Stop on Loss</div>
              <div className="Right">
                {currencies.get(this.props.currency)!!.symbol}0.00
              </div>
            </div>
            <div className="RowHeader">
              <div className="CanvasField">
                <div className="AmountField">
                  <div className="IconValue">
                    <input
                      type="number"
                      step=".000001"
                      min={0}
                      max={100}
                      onChange={(e) =>
                        this.setState({ stopOnLoss: Number(e.target.value) })
                      }
                      defaultValue={this.state.stopOnLoss}
                    />
                    <div className="ProfitCurrency">
                      <img
                        src={`/assets/currencies/${currencies.get(this.props.currency)!!.img
                          }`}
                        className="Currency"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Roulette;
